<div *ngIf="criticalParameterIndex > 0">
  <hr>
</div>
<div class="form-container">
  <div *ngIf="criticalParameter.$$deletedCode" class="notification-backdrop">
    Deleted&nbsp;
    <button mat-icon-button matTooltip="Undo" (click)="undoDelete()">
      <mat-icon svgIcon="undo"></mat-icon>
    </button>
  </div>

  <div class="">
    <div class="">
      <button mat-icon-button matTooltip="Delete Critical Parameter {{(criticalParameterIndex+1)}}"
        (click)="confirmDeleteCriticalParameter()">
        <mat-icon svgIcon="delete_forever"></mat-icon>
      </button>
    </div>
  </div>

  <div class="form-row">
    <!--
    <div class="delete-container">
      <button mat-icon-button matTooltip="Delete Critical Parameter {{(criticalParameterIndex+1)}}"
        (click)="confirmDeleteCriticalParameter()">
        <mat-icon svgIcon="delete_forever"></mat-icon>
      </button>
      <br>
      <div class="padleft15px"><b>{{(criticalParameterIndex+1)}}</b></div>
    </div>
    -->

    <app-cv-input class="width180px padright20px"
      *ngIf="configSettingsDisplay['propertyName'] || (configSettingsDisplay['propertyName'] === undefined && true)"
      domain="SSG_CRITICAL_PROPERTY_NAME" required='true' title="Property Name" [model]="criticalParameter.name"
      (valueChange)="updatePropertyName($event)">
    </app-cv-input>

    <mat-form-field class="amount-average col-5-1"
      *ngIf="configSettingsDisplay['average'] || (configSettingsDisplay['average'] === undefined && true)">
      <input type="number" matInput placeholder="Average" [formControl]="averageControl" />
    </mat-form-field>

    <mat-form-field class="low-limit col-5-1"
      *ngIf="configSettingsDisplay['lowLimit'] || (configSettingsDisplay['lowLimit'] === undefined && true)">
      <input type="number" matInput placeholder="Low Limit" [formControl]="lowLimitControl" />
    </mat-form-field>

    <mat-form-field class="high-limit col-5-1"
      *ngIf="configSettingsDisplay['highLimit'] || (configSettingsDisplay['highLimit'] === undefined && true)">
      <input type="number" matInput placeholder="High Limit" [formControl]="highLimitControl" />
    </mat-form-field>

    <mat-form-field class="amount-units width130px"
      *ngIf="configSettingsDisplay['units'] || (configSettingsDisplay['units'] === undefined && true)">
      <mat-label>Units</mat-label>
      <mat-select [formControl]="unitsControl">
        <mat-option [value]='null'><span class='deselect'>Clear selection</span></mat-option>
        <mat-option *ngFor="let unit of amountUnitList" [value]="unit.value">
          {{unit.display}}
        </mat-option>
        <mat-option
          *ngIf="privateSubstanceAmount.units && !inCV(  amountUnitList, privateSubstanceAmount.units) && privateSubstanceAmount.units !== ''"
          value="{{privateSubstanceAmount.units}}">{{privateSubstanceAmount.units}} (not in CV)</mat-option>
        <mat-option value='other'>Other (New Value)</mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="privateSubstanceAmount.units === 'other'" class="custom">
      <input matInput placeholder="Custom value" (focusout)="updateUnits($event.target)" name="custom" />
    </div>

  </div> <!-- form row -->

  <div class="form-row">
    <app-cv-input class="col-3-1"
      *ngIf="configSettingsDisplay['propertyType'] || (configSettingsDisplay['propertyType'] === undefined && true)"
      domain="PROPERTY_TYPE" required='true' title="Property Type" [model]="criticalParameter.propertyType"
      (valueChange)="criticalParameter.propertyType = $event">
    </app-cv-input>

    <div class="col-3-1 related-substance"
      *ngIf="configSettingsDisplay['referencedSubstance'] || (configSettingsDisplay['referencedSubstance'] === undefined && true)">
      <app-substance-selector (selectionUpdated)="relatedSubstanceUpdated($event)"
        eventCategory="substanceRelationshipRelatedSub" placeholder="Referenced Substance" header="Reference Substance"
        [subuuid]="relatedSubstanceUuid">
      </app-substance-selector>
    </div>

    <mat-form-field class="col-3-1"
      *ngIf="configSettingsDisplay['amountType'] || (configSettingsDisplay['amountType'] === undefined && true)">
      <mat-label>Amount Type</mat-label>
      <mat-select [formControl]="typeControl">
        <mat-option [value]='null'><span class='deselect'>Clear selection</span></mat-option>
        <mat-option *ngFor="let type of amountTypeList" [value]="type.value">
          {{type.display}}
        </mat-option>
        <mat-option
          *ngIf="privateSubstanceAmount.type && !inCV(  amountTypeList, privateSubstanceAmount.type) && privateSubstanceAmount.type !== ''"
          value="{{privateSubstanceAmount.type}}">{{privateSubstanceAmount.type}} (not in CV)</mat-option>
        <mat-option value='other'>Other (New Value)</mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="privateSubstanceAmount.type === 'other'" class="custom">
      <input matInput placeholder="Custom value" (focusout)="updateType($event.target)" name="custom" />
    </div>
  </div>

  <div class="form-row">
    <mat-form-field class="low col-3-1"
      *ngIf="configSettingsDisplay['low'] || (configSettingsDisplay['low'] === undefined && true)">
      <input type="number" matInput placeholder="Low" [formControl]="lowControl" />
    </mat-form-field>

    <mat-form-field class="high col-3-1"
      *ngIf="configSettingsDisplay['high'] || (configSettingsDisplay['high'] === undefined && true)">
      <input type="number" matInput placeholder="High" [formControl]="highControl" />
    </mat-form-field>

    <mat-form-field class="non-numeric-value col-3-1"
      *ngIf="configSettingsDisplay['nonNumericValue'] || (configSettingsDisplay['nonNumericValue'] === undefined && true)">
      <input matInput placeholder="Non-numeric Value" [formControl]="nonNumericValueControl" />
    </mat-form-field>
  </div>

  <!-- BEGIN: Parameters -->
  <div class="form-row padleft15px"
    *ngIf="configSettingsDisplay['parameters'] || (configSettingsDisplay['parameters'] === undefined && true)">
    <div>
      <h4>
        Parameters
        <button mat-icon-button (click)="openPropertyParameter()" matTooltip="Add parameter">
          <mat-icon svgIcon="add_circle_outline"></mat-icon>
        </button>
      </h4>

      <mat-list *ngIf="criticalParameter.parameters">
        <div mat-list-item *ngFor="let parameter of criticalParameter.parameters; index as index">
          <button mat-icon-button (click)="deleteParameter(index)" class="parameter-delete-icon">
            <mat-icon svgIcon="delete_forever"></mat-icon>
          </button>

          <span>{{parameter.name}}</span>
          <span *ngIf="parameter.value != null">
            <span>
              &nbsp;
              {{parameter.value.type}}
            </span>
            <span *ngIf="parameter.value.average != null && parameter.value.average != ''">
              &nbsp;-
              &nbsp;
              {{parameter.value.average}}
              &nbsp;
              {{parameter.value.units}}
            </span>
            <span *ngIf="(parameter.value.low != null && parameter.value.low != '')
                || (parameter.value.high != null && parameter.value.high != '')">
              <span *ngIf="parameter.value.average == null || parameter.value.average == ''">
                &nbsp;
                -
              </span>
              &nbsp;
              [
              <span *ngIf="parameter.value.high == null || parameter.value.high == ''">
                >
              </span>
              <span *ngIf="parameter.value.low == null || parameter.value.low == ''">
                < </span>
                  <span *ngIf="parameter.value.low != null && parameter.value.low != ''">
                    {{parameter.value.low}}
                  </span>
                  <span *ngIf="parameter.value.low != null && parameter.value.low != ''
                  && parameter.value.high != null && parameter.value.high != ''">
                    &nbsp;to&nbsp;
                  </span>
                  <span *ngIf="parameter.value.high != null && parameter.value.high != ''">
                    {{parameter.value.high}}
                  </span>
                  ]
                  <span *ngIf="parameter.value.average == null || parameter.value.average == ''">
                    &nbsp;
                    {{parameter.value.units}}
                    &nbsp;
                    (average)
                  </span>
              </span>
              <span *ngIf="parameter.value.average != null && parameter.value.average != ''">
                &nbsp;
                (average)
              </span>
              <span *ngIf="(parameter.value.lowLimit != null && parameter.value.lowLimit != '')
                    || (parameter.value.highLimit != null && parameter.value.highLimit != '')">
                &nbsp;-
                &nbsp;
                [
                <span *ngIf="parameter.value.highLimit == null || parameter.value.highLimit == ''">
                  >
                </span>
                <span *ngIf="parameter.value.lowLimit == null || parameter.value.lowLimit == ''">
                  < </span>
                    <span *ngIf="parameter.value.lowLimit != null && parameter.value.lowLimit != ''">
                      {{parameter.value.lowLimit}}
                    </span>
                    <span *ngIf="parameter.value.lowLimit != null && parameter.value.lowLimit != ''
                      && parameter.value.highLimit != null && parameter.value.highLimit != ''">
                      &nbsp;to&nbsp;
                    </span>
                    <span *ngIf="parameter.value.highLimit != null && parameter.value.highLimit != ''">
                      {{parameter.value.highLimit}}
                    </span>
                    ]
                    &nbsp;(limits)
                </span>
                <span *ngIf="parameter.value.nonNumericValue">
                  &nbsp;-&nbsp;
                  {{parameter.value.nonNumericValue}}
                </span>
              </span>
              <button mat-icon-button (click)="openPropertyParameter(parameter)" class="parameter-icon">
                <mat-icon svgIcon="edit"></mat-icon>
              </button>
        </div>
      </mat-list>

    </div>
  </div>
  <!-- END: form-row parameters -->

  <!-- Amount -->
  <!--
  <div class="amount-form-container"
    *ngIf="criticalParameter.propertyType !== 'PROTEIN FEATURE' && criticalParameter.propertyType !== 'NUCLEIC ACID FEATURE'">
    <div class="amount-title"><b>Amount</b></div>
    <app-amount-form [substanceAmount]="criticalParameter.value"></app-amount-form>
  </div>
 -->

  <!--  COMMENTING NOW -->
  <!--
  <div class="padleft15px padtop10px padbottom10px"><b>Amount</b></div>

  <form class="amount-form">
    <div class="form-row padleft15px">
      <mat-form-field class="col-4-1">
        <mat-label>Type</mat-label>
        <mat-select [formControl]="typeControl">
          <mat-option [value]='null'><span class='deselect'>Clear selection</span></mat-option>
          <mat-option *ngFor="let type of amountTypeList" [value]="type.value">
            {{type.display}}
          </mat-option>
          <mat-option
            *ngIf="privateSubstanceAmount.type && !inCV(  amountTypeList, privateSubstanceAmount.type) && privateSubstanceAmount.type !== ''"
            value="{{privateSubstanceAmount.type}}">{{privateSubstanceAmount.type}} (not in CV)</mat-option>
          <mat-option value='other'>Other (New Value)</mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="privateSubstanceAmount.type === 'other'" class="custom">
        <input matInput placeholder="Custom value" (focusout)="updateType($event.target)" name="custom" />
      </div>
      -->
  <!--
      <mat-form-field class="amount-average col-4-1">
        <input type="number" matInput placeholder="Average" [formControl]="averageControl" />
      </mat-form-field>
      -->
  <!--
      <mat-form-field class="low col-4-1">
        <input type="number" matInput placeholder="Low" [formControl]="lowControl" />
      </mat-form-field>
      <mat-form-field class="high col-4-1">
        <input type="number" matInput placeholder="High" [formControl]="highControl" />
      </mat-form-field>

      <mat-form-field class="non-numeric-value col-4-1">
        <input matInput placeholder="Non-numeric Value" [formControl]="nonNumericValueControl" />
      </mat-form-field>
    </div>
  </form>
   -->
  <!-- Amount form-row -->
</div>