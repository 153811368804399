<h1 mat-dialog-title>
    Success
</h1>
<div mat-dialog-content>
    Update was performed.
</div>
<div class="flex-row" mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="dismissDialog('continue')">Continue Editing</button>
    <button mat-flat-button color="primary" (click)="dismissDialog('browse')">Go to Browse</button>
    <button mat-flat-button color="primary" (click)="dismissDialog('view')">View Substance</button>
</div>