<div class="top-fixed" [ngClass]="{'top-fixed-top': showHeaderBar === 'true'}">
  <div class="actions-container">
    <button mat-flat-button color="primary" (click)="showJSON()">Show JSON</button>
    <!--
    <span class = "middle-fill"></span>
    -->
    &nbsp;&nbsp;&nbsp;&nbsp;
    <a  mat-flat-button color="primary" [href]="downloadJsonHref" (click)="saveJSON()" download="{{jsonFileName}}.json">
      <span>Save as JSON</span>
    </a>

    <button mat-flat-button color="primary" class="import-button" (click)="importDialog()">Import JSON</button>

    <button mat-flat-button class="hide-show-messages" (click)="toggleValidation()" [disabled]="isLoading"
      *ngIf="(validationMessages && validationMessages.length > 0)">
      {{showSubmissionMessages ? 'Hide' : 'Show'}} messages
      <mat-icon [svgIcon]="showSubmissionMessages ? 'chevron_up' : 'chevron_down'"></mat-icon>
    </button>
    <span class="middle-fill"></span>
    <button mat-flat-button color="primary" class="validate-button" (click)="validate()">Validate and Submit</button>
    <button mat-flat-button color="primary" class="validate-button" [disabled]="!canApprove || approving"
      *ngIf="definition && definition.substanceClass!=='concept' && !definition.approvalID && !imported && definitionType !== 'ALTERNATIVE'"
      (click)="validate('approval')">Approve</button>
  </div>
  <div [ngClass]="{'submission-messages': true, collapsed: !showSubmissionMessages, expanded: showSubmissionMessages}">
    <div class="submission-message" *ngIf="submissionMessage">
      {{submissionMessage}}
    </div>
    <div *ngIf="validationMessages">
      <span *ngIf=" validationMessages.length > 0">
        <div class="submission-message" *ngIf='!serverError && !approving'>
          Please correct or dismiss the following errors and submit again:
        </div>
        <div class="validation-message" *ngFor="let message of validationMessages; index as i">
          <div class="message-type" [ngClass]="message.messageType == 'ERROR' ? 'error-message' : 'warning-message'">
            {{message.messageType}}</div>
          <div class="message">{{message.message}}<span *ngFor="let link of message.links"><br />
              <a [href]="fixLink(link.href)" class="internal-link" target="_blank">{{link.text}}</a></span>
          </div>
          <button mat-icon-button matTooltip="Dismiss" *ngIf="message.messageType != 'ERROR' && !approving"
            (click)="dismissValidationMessage(i)">
            <mat-icon svgIcon="cancel"></mat-icon>
          </button>
        </div>
      </span>
      <div class="dismiss-container">
        <span class="middle-fill"></span>
        <button mat-flat-button color="primary" *ngIf="!approving" (click)="submit()"
          [disabled]="isLoading || (this.validationResult === false)">{{validationMessages && validationMessages.length > 0?'Dismiss All and ':''}}
          Submit</button>
        <button mat-flat-button *ngIf="!approving" (click)="showSubmissionMessages = false">
          Cancel</button>
        <button mat-flat-button color="primary" *ngIf="approving" (click)="approve()"
          [disabled]="isLoading || !canApprove"> Confirm Approval</button>
      </div>
    </div>
  </div>
</div>

<div class="form-header-title form-header" [ngClass]="{'form-header-title-top': showHeaderBar === 'true'}">
  <div>
    <span class="colorbluefda fontbold">{{(uuid && !imported) ? 'Editing ' : 'Registering New '}} -
      Specified Substance Group 4 Manufacturing
    </span>
  </div>
</div>

<div class="form-content-container">
  <div class="scrollable-container">
    <div class="cards-container maximize-width-card">
      <mat-accordion multi="true">
        <mat-expansion-panel #expansionPanel [expanded]="expandedComponents.indexOf(section.dynamicComponentName) > -1"
          *ngFor="let section of formSections; index as index; last as isLast" [id]="section.dynamicComponentName"
          [ngClass]="{'hidden': section.isHidden}">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h2>{{section.menuLabel}}</h2>
              <div class="middle-fill"></div>
              <div *ngIf="section.canAddItem">
                <button mat-flat-button color="primary" id="Add new {{section.menuLabel}}"
                  (click)="section.addItem(); $event.stopPropagation()" matTooltip="Add new {{section.menuLabel}}">
                  Add {{section.menuLabel}}
                  <mat-icon svgIcon="add_circle_outline"></mat-icon>
                </button>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div #dynamicComponent></div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
