<!-- Buttons: delete, review -->

<div class="marginbottomminus17px padleft250px">
  <button mat-icon-button [ngStyle]="{'color':'green'}" [disabled]="totalComponent == 1"
    (click)="confirmDeleteComponent(prodComponentIndex)"
    matTooltip="Delete Component Details {{prodComponentIndex + 1}}">
    <mat-icon [class.disabled]="totalComponent == 1">delete_forever</mat-icon>
  </button>
  &nbsp;&nbsp;
  <button mat-icon-button [ngStyle]="{'color':'green'}" (click)="copyProductComponent()"
    matTooltip="Copy Manufacture Item Details {{prodComponentIndex + 1}} to New Manufacture Item">
    <mat-icon>file_copy</mat-icon>
  </button>
</div>


<!-- Display Component/Manufacture -->
<fieldset class="border">
  <legend class="border">Manufacture Item Details&nbsp;<b><span
        class="colorgreen font17px">{{prodComponentIndex + 1}}</span></b>
  </legend>

  <div class="form-row margintop10px">
    <app-cv-input class="col-2-4" domain="DOSAGE_FORM" title="Dosage Form" name="dosageForm"
      [model]="productComponent.dosageForm" (valueChange)="productComponent.dosageForm = $event">
    </app-cv-input>
    <app-cv-input class="col-1-4" domain="PROD_CHARACTER_COLOR" title="Color Name" name="charColor"
      [model]="productComponent.charColor" (valueChange)="productComponent.charColor = $event">
    </app-cv-input>
    <app-cv-input class="col-1-4" domain="PROD_CHARACTER_FLAVOR" title="Flavor Name" name="charFlavor"
      [model]="productComponent.charFlavor" (valueChange)="productComponent.charFlavor = $event">
    </app-cv-input>
  </div>

  <div class="form-row">
    <mat-form-field class="col-1-4">
      <input matInput placeholder="Amount" [(ngModel)]="productComponent.amount" name="amount" />
    </mat-form-field>
    <app-cv-input class="col-1-4" domain="PROD_UNIT" title="Unit" name="unit"
      [model]="productComponent.unit" (valueChange)="productComponent.unit = $event">
    </app-cv-input>
    <mat-form-field class="col-1-4">
      <input matInput placeholder="Manufacture Code" [(ngModel)]="productComponent.manufactureCode"
        name="manufactureCode" />
    </mat-form-field>
    <app-cv-input class="col-1-4" domain="PROD_MANUFACTURE_CODE_TYPE" title="Manufacture Code Type" name="manufactureCodeType"
      [model]="productComponent.manufactureCodeType" (valueChange)="productComponent.manufactureCodeType = $event">
    </app-cv-input>
  </div>

  <div class="form-row">
    <app-cv-input class="col-1-4" domain="PROD_CHARACTER_SHAPE" title="Shape Name" name="charShape"
      [model]="productComponent.charShape" (valueChange)="productComponent.charShape = $event">
    </app-cv-input>
    <app-cv-input class="col-1-4" domain="PROD_CHARACTER_FRAGMENTS" title="Scoring" name="charNumFragments"
      [model]="productComponent.charNumFragments" (valueChange)="productComponent.charNumFragments = $event">
    </app-cv-input>
    <mat-form-field class="col-1-4">
      <input matInput placeholder="Size" [(ngModel)]="productComponent.charSize" name="charSize" />
    </mat-form-field>
    <mat-form-field class="col-1-4">
      <input matInput placeholder="Imprint Text" [(ngModel)]="productComponent.charImprintText"
        name="charImprintText" />
    </mat-form-field>
  </div>

  <!-- PRODUCT LOT LIST BEGIN -->
  <div class="">
    <br>
    <hr>
    <br>
    <span matBadge="{{productComponent.productLotList.length}}" matBadgeOverlap="false">
      <span class="titlebrown">LOT </span>
    </span>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <button mat-raised-button color="primary" (click)="addNewProductLot(prodComponentIndex)" matTooltip="Add New Lot">
      <mat-icon svgIcon="add_circle_outline"></mat-icon>Add Lot
    </button>
    <br>
    <div class="marginbottom10px" *ngFor="let productLot of productComponent.productLotList; let prodLotIndex = index">
      <app-product-lot-form [productLot]="productLot" [totalLot]="productComponent.productLotList.length"
        [prodLotIndex]="prodLotIndex" [prodComponentIndex]="prodComponentIndex"
        (expiryDateMessageOut)="expiryDateMessageOutChange($event)"
        (manufactureDateMessageOut)="manufactureDateMessageOutChange($event)">
      </app-product-lot-form>
    </div>
  </div>
  <!-- PRODUCT LOT LIST END -->

</fieldset>
<br><br>