<div mat-dialog-content>
  <div class="structure-image-content">
    <button mat-mini-fab (click)="dismissDialog()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <div class="approval" *ngIf="approvalID">
      <div *ngIf="uuid !== null; else noUuid">
        <a [routerLink]="['/substances', uuid]" target="_blank">
          <span class="viewrecord">{{approvalID}}</span>
        </a>
      </div>
      <ng-template #noUuid>
        {{approvalID}}
      </ng-template>
    </div>
    <div style="height:500px;min-height:501px;width:500px;min-width:501px;margin:auto">
      <img *ngIf="structure" appSubstanceImage [entityId]="structure" size="500" stereo="true">
    </div>
    <div class="displayname" *ngIf="displayName">
      <div *ngIf="uuid !== null; else noDisplayUuid">
        <a [routerLink]="['/substances', uuid]" target="_blank">
          <span class="viewrecord">{{displayName}}</span>
        </a>&nbsp;
        <a [routerLink]="['/substances', uuid]" target="_blank">
          <span class="viewrecord">(view record)</span>
        </a>
      </div>
      <ng-template #noDisplayUuid>
        {{displayName}}
      </ng-template>
    </div>
    <mat-tab-group *ngIf="smiles || (names && names.length > 0) || inchi || inchiKey">
      <mat-tab label="Systematic Names" *ngIf="names && names.length > 0">
        <ul>
          <li *ngFor="let name of names">
            {{name}}
          </li>
        </ul>
      </mat-tab>
      <mat-tab label="SMILES" *ngIf="smiles">
        <textarea cdkTextareaAutosize id="smiles" readonly style="height:45px;">{{smiles}}</textarea>
      </mat-tab>
      <mat-tab label="InChi" *ngIf="inchi || inchiKey">
        <textarea cdkTextareaAutosize id="inchi" readonly style="height:45px;">{{inchi}}</textarea>
        <textarea cdkTextareaAutosize id="inchiKey" readonly style="height:45px;">{{inchiKey}}</textarea>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<!-- <div mat-dialog-actions class='flex-row'>
  <span class="middle-fill"></span>
  <button class="modal-space-saver" mat-button (click)="dismissDialog()">Close</button>
</div> -->
