<div class = "main-container">
  <mat-sidenav-container [hasBackdrop]="hasBackdrop">
    <mat-sidenav  [disableClose]="!isCollapsed"  #matSideNavInstance mode="side" opened="true">



    <div class = "side-bar" >
        <div class="register-main">
            <mat-card-content class="left-search" >
              <mat-card class = "side-header" style = "margin-bottom:10px;">
                  <h2 class = "quick-links">Quick Links</h2>
              </mat-card>
                <mat-accordion multi="true">
                    <mat-expansion-panel
                      [expanded]="true">
                      <mat-expansion-panel-header>
                        <mat-panel-title class = "panel-header">
                             Substances
                        </mat-panel-title>
                      </mat-expansion-panel-header>

                            <mat-nav-list>
                                <a mat-list-item routerLink="/browse-substance">
                                  <mat-icon matListIcon svgIcon="glasses"></mat-icon>
                                  Browse Substances
                                </a>
                                  <mat-divider></mat-divider>

                                  <a mat-list-item routerLink="/structure-search">
                                    <mat-icon matListIcon svgIcon="search"></mat-icon>
                                    Structure Search
                                  </a>
                                  <mat-divider></mat-divider>
                                  <a mat-list-item routerLink="/sequence-search">
                                    <mat-icon matListIcon svgIcon="search"></mat-icon>
                                    Sequence Search
                                  </a>
                                  <mat-divider></mat-divider>
                                  <a mat-list-item routerLink="/advanced-search">
                                    <mat-icon matListIcon svgIcon="youtube"></mat-icon>
                                    Advanced Search
                                  </a>
                                  </mat-nav-list>

                    </mat-expansion-panel>
                    <mat-expansion-panel
                    [expanded]="false" *ngIf="loadedComponents">
                    <mat-expansion-panel-header>
                      <mat-panel-title  class = "panel-header">
                            Other
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                      <mat-nav-list>
                          <span>
                            <span *ngIf = "loadedComponents.applications">
                              <mat-divider></mat-divider>
                              <a mat-list-item routerLink="/browse-applications">
                                <mat-icon matListIcon svgIcon="glasses"></mat-icon>
                                Browse Applications
                              </a>
                              </span>
                              <span *ngIf = "loadedComponents.products">
                              <mat-divider></mat-divider>
                              <a mat-list-item routerLink="/browse-products">
                                <mat-icon matListIcon svgIcon="glasses"></mat-icon>
                                Browse Products
                              </a>
                              </span>
                              <span *ngIf = "loadedComponents.clinicaltrials">
                              <mat-divider></mat-divider>
                              <a mat-list-item routerLink="/browse-clinical-trials">
                                <mat-icon matListIcon svgIcon="glasses"></mat-icon>
                                Browse Clinical Trials
                              </a>
                              </span>
                              <span *ngIf = "loadedComponents.adverseevents">
                                <span *ngIf="!adverseEventShinyHomepageDisplay">
                                    <mat-divider></mat-divider>
                                    <a mat-list-item routerLink="/browse-adverse-events">
                                      <mat-icon matListIcon svgIcon="glasses"></mat-icon>
                                      Browse Adverse Events
                                    </a>Other
                                </span>Other
                              <div *ngIf="adverseEventShOtherinyHomepageDisplay === true">
                                <mat-divider></mat-divider>
                                <a mat-list-item [href]="adverseEventShinyHomepageURL" target="_blank">
                                  <mat-icon matListIcon svgIcon="glasses"></mat-icon>
                                  Browse Adverse Events
                                </a>
                              </div>
                              </span>

                              </span>
                      </mat-nav-list>
                    <ng-container>

                    </ng-container>
                  </mat-expansion-panel>
                  <mat-expansion-panel
                  [expanded]="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title  class = "panel-header">
                           Register
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                    <mat-nav-list class="main-list">
                        <a mat-list-item routerLink="/substances/register/chemical">
                          <mat-icon matListIcon svgIcon="outline-description"></mat-icon>
                          Chemical
                        </a>
                        <mat-divider></mat-divider>
                        <a mat-list-item routerLink="/substances/register/protein">
                          <mat-icon matListIcon svgIcon="outline-description"></mat-icon>
                          Protein
                        </a>
                        <mat-divider></mat-divider>
                        <a mat-list-item routerLink="/substances/register/polymer">
                          <mat-icon matListIcon svgIcon="outline-description"></mat-icon>
                          Polymer
                        </a>
                        <mat-divider></mat-divider>
                        <a mat-list-item routerLink="/substances/register/nucleicAcid">
                          <mat-icon matListIcon svgIcon="outline-description"></mat-icon>
                          Nucleic Acid
                        </a>
                        <mat-divider></mat-divider>
                        <a mat-list-item routerLink="/substances/register/structurallyDiverse">
                          <mat-icon matListIcon svgIcon="outline-description"></mat-icon>
                          Structurally Diverse
                        </a>
                        <mat-divider></mat-divider>
                        <a mat-list-item routerLink="/substances/register/concept">
                          <mat-icon matListIcon svgIcon="outline-description"></mat-icon>
                          Concept
                        </a>
                        <mat-divider></mat-divider>
                        <a mat-list-item routerLink="/substances/register/mixture">
                          <mat-icon matListIcon svgIcon="outline-description"></mat-icon>
                          Mixture
                        </a>
                          <mat-divider></mat-divider>

                          <a mat-list-item routerLink="/substances/register/specifiedSubstanceG1">
                            <mat-icon matListIcon svgIcon="outline-description"></mat-icon>

                            Specified Substance Group 1
                          </a>

                      </mat-nav-list>
                  <ng-container>

                  </ng-container>
                </mat-expansion-panel>
                <mat-expansion-panel  *ngIf="loadedComponents" 
                [expanded]="false">
                <mat-expansion-panel-header>
                  <mat-panel-title  class = "panel-header">
                       Register Other
                  </mat-panel-title>
                </mat-expansion-panel-header>
                  <div>
                  <mat-nav-list class="other-list">

                     <!-- <a mat-list-item [href]="baseDomain + clasicBaseHref + 'wizard?kind=specifiedSubstanceG2'">
                        <mat-icon matListIcon svgIcon="outline-description"></mat-icon>

                        Specified Substance Group 2
                        <mat-icon class="shrunken-icon" svgIcon="open_in_new"></mat-icon>

                      </a>
                      <mat-divider></mat-divider>
                      <a mat-list-item routerLink="/substances/register/specifiedSubstanceG3">
                        <mat-icon matListIcon svgIcon="outline-description"></mat-icon>
                        Specified Substance Group 3
                      </a>
                      
                      <a mat-list-item [href]="baseDomain + clasicBaseHref + 'wizard?kind=specifiedSubstanceG3'">
                        <mat-icon matListIcon svgIcon="outline-description"></mat-icon>
                        Specified Substance Group 3
                        <mat-icon  class = "shrunken-icon"  svgIcon="open_in_new"></mat-icon>
                      </a>
                      <mat-divider></mat-divider>
                      <a mat-list-item [href]="baseDomain + clasicBaseHref + 'wizard?kind=specifiedSubstanceG4'">
                        <mat-icon matListIcon svgIcon="outline-description"></mat-icon>
                        Specified Substance Group 4
                        <mat-icon class="shrunken-icon" svgIcon="open_in_new"></mat-icon>
                      </a>
                      -->

                      <span *ngIf="loadedComponents">
                        <span *ngIf = "loadedComponents.products">
                        <mat-divider></mat-divider>
                        <a mat-list-item routerLink="/product/register">
                          <mat-icon matListIcon svgIcon="outline-description"></mat-icon>
                          Product
                        </a>
                        <!--
                        <a mat-list-item [href]="baseDomain + clasicBaseHref + 'registerProduct'">
                          <mat-icon matListIcon svgIcon="outline-description"></mat-icon>
                          Product
                          <mat-icon class="shrunken-icon" svgIcon="open_in_new"></mat-icon>
                        </a>
                        -->
                      </span>
                      <span *ngIf = "loadedComponents.applications">
                          <mat-divider></mat-divider>
                        <a mat-list-item routerLink="/application/register">
                          <mat-icon matListIcon svgIcon="outline-description"></mat-icon>
                          Application
                        </a>

                        <!--
                        <a mat-list-item [href]="baseDomain + clasicBaseHref + 'registerApplication'">
                          <mat-icon matListIcon svgIcon="outline-description"></mat-icon>
                          Application
                          <mat-icon class="shrunken-icon" svgIcon="open_in_new"></mat-icon>
                        </a>
                        -->
                        </span>
                        <span *ngIf = "loadedComponents.impurities">

                        <mat-divider></mat-divider>
                        <a mat-list-item routerLink="/impurities/register">
                          <mat-icon matListIcon svgIcon="outline-description"></mat-icon>
                          Impurities
                        </a>
                        </span>
                        

                       <!-- <mat-divider></mat-divider>
                        <a mat-list-item [href]="baseDomain + clasicBaseHref + 'registerBiomarker'">
                          <mat-icon matListIcon svgIcon="outline-description"></mat-icon>
                          Biomarker
                          <mat-icon class="shrunken-icon" svgIcon="open_in_new"></mat-icon>
                        </a>

                        <mat-divider></mat-divider>
                        <a mat-list-item [href]="baseDomain + clasicBaseHref + 'registerIndication'">
                          <mat-icon matListIcon svgIcon="outline-description"></mat-icon>
                          Indication
                          <mat-icon class="shrunken-icon" svgIcon="open_in_new"></mat-icon>
                        </a>-->

                      </span>

                    </mat-nav-list>
                  </div>
                <ng-container>

                </ng-container>
              </mat-expansion-panel>
                  </mat-accordion>
            </mat-card-content>
          </div>
        </div>
      </mat-sidenav>
      <mat-sidenav-content>
        <button aria-label="Open Side Navigation" class="expand-sidenav mat-elevation-z4" (click)="openSideNav()">
          <mat-icon svgIcon="chevron_right"></mat-icon>
        </button>
    <div class="side-nav-content" [ngClass] = "isClosedWelcomeMessage? 'topMargin' : ''" >
        <mat-card class="welcome-banner home-card"  *ngIf="bannerMessage && bannerMessage !== ''">
            <div class = "banner-main" [innerHTML] = "bannerMessage">
            </div>

          <!--  <div class="close-container" style = "width:50px">
                <button mat-icon-button (click)="closeWelcomeMessage()">
                  <mat-icon svgIcon="close"></mat-icon>
                </button>
              </div>-->


          </mat-card>
    <mat-card class=" home-card" [ngClass] = "(bannerMessage && bannerMessage !== '')? '' : ''" >

        <div class = "flex-row image-row">
          <div class = "image-container">
            <img class = "display-image" src = "{{imageLoc}}pills1.png" alt="first display image" />
          </div>
          <div class = "image-container">
              <img class = "display-image" src = "{{imageLoc}}chemistry2.png" alt="second display image"/>
            </div>
            <div class = "image-container">
                <img class = "display-image" src = "{{imageLoc}}hydrangea.png" alt="third display image"/>
              </div>
              <div class = "image-container">
                  <img class = "display-image" src = "{{imageLoc}}dna.png" alt="fourth display image"/>
                </div>
           </div>

      <div class = "row">
        <h1 class = "home">Global Substance Registration System - GSRS</h1>
        <div style = "padding-left:15px;">
          GSRS has been developed to assist regulators in managing substance information. It was designed to facilitate global monitoring of human and animal medicinal, food, tobacco, and cosmetic products. GSRS provides unique substance identifiers consistent with the ISO 11238 standard. FDA’s GSRS specifically generates the Unique Ingredient Identifiers (UNIIs) used in electronic listing and other regulatory activities.
        </div>

      </div>
      <div class = "flex-row" style = "padding:5px;">
          <app-substance-text-search class="main-search" placeholder = "Search Substances" styling = "homePage" [searchValue]="searchValue"
          (searchPerformed)="processSubstanceSearch($event)" eventCategory="topSearch" (opened)="increaseMenuZindex()"
          (closed)="removeZindex()">
        </app-substance-text-search>
      </div>
    <div class = "flex-row even" style = " margin-bottom: 25px;">
        <div class="button-container">
            <a mat-raised-button class="big-button" routerLink="/browse-substance">Browse Substances</a>
          </div>
          <div class="button-container" *ngIf="loadedComponents && loadedComponents.applications">

              <a mat-raised-button class="big-button" routerLink="/browse-applications">Browse Applications</a>
            </div>
          <div class="button-container">
              <a mat-raised-button class="big-button" routerLink="/structure-search">Structure Search</a>
            </div>
            <div class="button-container">
                <a mat-raised-button class="big-button" routerLink="/sequence-search">Sequence Search</a>
              </div>
            </div>
          <mat-divider class = "divide-test"></mat-divider>

    <div class = "flex-row" style = "padding-bottom: 10px;">
      <div class = "data">
          Total substances: {{total | number:'1.0':'en-US'}}
      </div>
      </div>
      <div class = "flex-row test" style = "padding-bottom: 10px;">
        <div *ngFor = "let link of customLinks" class = "custom-wrap" (click) = "routeToCustom(link)">
          <span class = "entry">
              <div class = "label">
                  {{link.display}}
                </div>
                <div class = "value">
                  {{link.total  | number:'1.0':'en-US'}}
                </div>
              </span>
        </div>
    </div>
    <div style = "padding-left:15px; padding-top: 10px;">
    </div>
    </mat-card>

    <mat-card class = "home-card" >
    <h2 class = "home" style = "padding-top:15px;">Helpful Resources</h2>
  </mat-card>
    <div class = "row3">
      <mat-card class="temprow left-link" id="resource1" *ngIf = "!excelCard">

          <h3 class = "resource-link"> <a class = "mat-raised-button mat-button mat-primary mat-primary-button trial-button" tabindex="0" role="link" (click) = "openModal(excel)" >GSRSFind Excel tools <mat-icon svgIcon="get_app"></mat-icon></a></h3>
          <div class = "img-container">
            <img class = "test" src = "{{imageLoc}}xlsx.png" alt="GSRS excel image"/>
         </div>
            <div class = "resource-desc"> GSRSFind is an add-in for Microsoft Excel that gives a user access to data in GSRS for both search/browse and creation/modification purposes. (Note: write access requires valid credentials!)
            GSRSFind can augment an existing spreadsheet with data from GSRS. For example, you can add chemical structures to a column of names or identifiers.
            </div>
          </mat-card>

          <mat-card class="temprow left-link" id="resource1" *ngIf = "excelCard">
            <h3 class = "resource-link"> <a class = "mat-raised-button mat-button mat-primary mat-primary-button trial-button" tabindex="0" role="link" (click) = "openModal(excel)" >{{excelCard.title? excelCard.title : 'no tile'}}</a></h3>
            <div class = "img-container">
              <img class = "test" src = "{{imageLoc}}{{excelCard.imageFile}}" alt="GSRS excel card" />
           </div>
              <div class = "resource-desc" [innerHtml] = "excelCard.description">  </div>
            </mat-card>

          <mat-card class="temprow right-link" id="resource2" *ngIf = "guideCard">
          <h3 class = "resource-link"> <a class = "mat-raised-button mat-button mat-primary mat-primary-button trial-button" tabindex="0" role="link" (click) = "openModal(guide)" >GSRS User Guide</a></h3>
          <div class = "img-container">
              <img class = "test" src = "{{imageLoc}}{{guideCard.imageFile}}" alt="GSRS guide card image" />
           </div>
          <div class = "resource-desc"  [innerHtml] = "guideCard.description">
         </div>
        </mat-card>
        <mat-card class="temprow right-link" id="resource2" *ngIf = "!guideCard">
            <h3 class = "resource-link"> <a class = "mat-raised-button mat-button mat-primary mat-primary-button trial-button" tabindex="0" role="link" (click) = "openModal(guide)" >GSRS User Guide</a></h3>
            <div class = "img-container">
                <img class = "test" src = "{{imageLoc}}guide.png" alt="GSRS guide card"/>
             </div>
            <div class = "resource-desc"> The GSRS user guide is comprehensive set of instructions for searching and registering new substances of all types in GSRS.
           </div>
          </mat-card>
      </div>
      <div class = "row3">
      <mat-card class="temprow left-link" id="resource2" *ngIf = "dictionaryCard">
              <h3 class = "resource-link"> <a class = "mat-raised-button mat-button mat-primary mat-primary-button trial-button" tabindex="0" role="link" (click) = "openModal(dictionary)" >GSRS Data Dictionary</a></h3>
              <div class = "img-container">
                  <img class = "test" src = "{{imageLoc}}{{dictionaryCard.imageFile}}" alt="GSRS dictionary card image"/>
               </div>
              <div class = "resource-desc"  [innerHtml] = "dictionaryCard.description">  
                </div>

          </mat-card>
          
          
          <mat-card class="temprow left-link" id="resource2" *ngIf = "!dictionaryCard">
                  <h3 class = "resource-link"> <a class = "mat-raised-button mat-button mat-primary mat-primary-button trial-button"  (click) = "openModal(dictionary)" >GSRS Data Dictionary</a></h3>
                  <div class = "img-container">
                      <img class = "test" src = "{{imageLoc}}dictionary.png" alt="GSRS dictionary card"/>
                   </div>
                  <div class = "resource-desc">  The GSRS Data Dictionary is a comprehensive spreadsheet of all properties of the substance object used by GSRS. You will find descriptions,  relative paths, data types, and more for every property present in the GSRS substance model.
                    </div>
    
              </mat-card>

          <mat-card class="temprow right-link" id="resource2">
                    <h3 class = "resource-link"> <a class = "mat-raised-button mat-button mat-primary mat-primary-button trial-button" target ="_blank" href = "https://gsrs.ncats.nih.gov/#/api#chemical%20search">GSRS API Documentation</a></h3>
                    <div class = "img-container">
                        <img class = "test" src = "{{imageLoc}}API.png" alt="GSRS API image"/>
                     </div>
                    <div class = "resource-desc">       Swagger documentation of the GSRS API calls with working examples and implementation notes.
                    </div>
          </mat-card>
        </div>
        <div class = "row3">
            <mat-card class="temprow left-link" id="resource2">

              <h3 class = "resource-link"> <a class = "mat-raised-button mat-button mat-primary mat-primary-button trial-button" tabindex="0" role="link" (click) = "openModal(schema)" >GSRS JSON schema</a></h3>
              <div class = "img-container">
                  <img class = "test" src = "{{imageLoc}}json.png" alt="GSRS JSON image"/>
               </div>
                  <div class = "resource-desc">    This file contains a JSON formatted schema used by GSRS for recording substances to help visualize the substance object described by the GSRS Data Dictionary.
                    </div>
                  </mat-card>
                  <mat-card class="temprow right-link" id="resource2">
                      <h3 class = "resource-link"> <a class = "mat-raised-button mat-button mat-primary mat-primary-button trial-button" target ="_blank" href = "https://www.fda.gov/industry/fda-resources-data-standards/substance-identification">ISO 11238 Standard</a></h3>
                      <div class = "img-container">
                          <img class = "test" src = "{{imageLoc}}iso2.png" alt="GSRS ISO image"/>
                       </div>
                      <div class = "resource-desc">       ISO 11238 is the standard that all substance data used by GSRS conforms to. ISO 11238 Substance Identification (SubID) provides an information model to define and identify substances within medicinal products or substances used for medicinal purposes.
                        </div>
                      </mat-card>
                      </div>



  <ng-template #excel>
      <div mat-dialog-content class = "button-modal">
          <a class = "modal-button" href = "{{excelCard.href}}">{{excelCard.templateDescription}}</a>
      </div>
      <div mat-dialog-actions>
          <span class="middle-fill"></span>
          <button class="mat-raised-button mat-primary" mat-dialog-close>Close</button>
        </div>
  </ng-template>

  <ng-template #schema>
      <div mat-dialog-content class = "button-modal">
          <a class = "modal-button" href = "https://gsrs.ncats.nih.gov/downloads/GSRS_schema_2_0_0.json">Click to download GSRS JSON schema.json</a>
      </div>
      <div mat-dialog-actions>
          <span class="middle-fill"></span>
          <button class="mat-raised-button mat-primary" mat-dialog-close>Close</button>
        </div>
  </ng-template>

  <ng-template #dictionary>
      <div mat-dialog-content class = "button-modal" >
          <a class = "modal-button" href = "{{dictionaryCard.href}}">{{dictionaryCard ? dictionaryCard.templateDescription : "click to download GSRS Data Dictionary.xlsx"}}</a>
      </div>
      <div mat-dialog-actions>
          <span class="middle-fill"></span>
          <button class="mat-raised-button mat-primary" mat-dialog-close>Close</button>
        </div>
  </ng-template>

  <ng-template #guide>
      <div mat-dialog-content class = "button-modal">
          <a class = "modal-button"href ="{{guideCard.href}}">{{guideCard ? guideCard.templateDescription : "click to download GSRS User Guide.docx"}}</a>
      </div>
      <div mat-dialog-actions>
          <span class="middle-fill"></span>
          <button class="mat-raised-button mat-primary" mat-dialog-close>Close</button>
        </div>
  </ng-template>
<!--
      <mat-card class = "home-card" >
    <h2 class = "home" style = "padding-top:15px;">Helpful Resources</h2>
      <div class="row resource-row" id="resource1">

          <h3 > <a class = "mat-raised-button mat-button mat-primary mat-primary-button trial-button" href = "https://gsrs.ncats.nih.gov/downloads/GinasExcelTools.zip">GSRSFind Excel tools</a></h3>
            <div class = "resource-desc"> GSRSFind is an add-in for Microsoft Excel that gives a user access to data in GSRS for both search/browse and creation/modification purposes. (Note: write access requires valid credentials!)
            GSRSFind can augment an existing spreadsheet with data from GSRS. For example, you can add chemical structures to a column of names or identifiers.
            </div>
          </div>
          <mat-divider class = "divide-test"></mat-divider>

          <div class="row resource-row" id="resource2">
          <h3><a class = "mat-raised-button mat-button mat-primary mat-primary-button trial-button" style = "color:white" href ="https://gsrs.ncats.nih.gov/downloads/Substance%20Registration%20-%20October%202019.docx">User Registration Guide</a></h3>

          <div class = "resource-desc"> The GSRS user registration guide is comprehensive set of instructions for registering new substances of all types in GSRS.
         </div>
        </div>
          <mat-divider class = "divide-test"></mat-divider>

          <div class="row resource-row" id="resource3">
              <h3 class = "resource-link"><a class = "mat-raised-button mat-button mat-primary mat-primary-button trial-button" href = "https://gsrs.ncats.nih.gov/downloads/GSRS_data_dictionary_11-20-19.xlsx">GSRS Data Dictionary</a></h3>
              <div class = "resource-desc">  The GSRS Data Dictionary is a comprehensive spreadsheet of all properties of the substance object used by GSRS. You will find descriptions,  relative paths, data types, and more for every property present in the GSRS substance model.
                </div>
            </div>

              <div class="row resource-row" id="resource4">
                  <h3 class = "resource-link"> <a class = "mat-raised-button mat-button mat-primary mat-primary-button trial-button" href = "https://gsrs.ncats.nih.gov/downloads/GSRS_schema_10_30_19.json">GSRS JSON schema</a></h3>
                  <div class = "resource-desc">    This file contains a JSON formatted schema used by GSRS for recording substances to help visualize the substance object described by the GSRS Data Dictionary.
                    </div>
                </div>

                <div class="row resource-row" id="resource5">
                    <h3 class = "resource-link"> <a class = "mat-raised-button mat-button mat-primary mat-primary-button trial-button" href = "https://gsrs.ncats.nih.gov/#/api#chemical%20search">GSRS API Documentation</a></h3>
                    <div class = "resource-desc">       Swagger documentation of the GSRS API calls with working examples and implementation notes.
                      </div>
                    </div>

                  <div class="row resource-row" id="resource5">
                      <h3 class = "resource-link"> <a class = "mat-raised-button mat-button mat-primary mat-primary-button trial-button" href = "https://www.iso.org/standard/69697.html">ISO 11238 Standard</a></h3>
                      <div class = "resource-desc">       ISO 11238 is the standard that all substance data used by GSRS conforms to. ISO 11238 Substance Identification (SubID) provides an information model to define and identify substances within medicinal products or substances used for medicinal purposes.
                        </div>
                      </div>

  </mat-card>

  <mat-card class = "home-card" >
      <h2 class = "home" style = "padding-top:15px;">Helpful Resources</h2>
      <div class = "test1">
        <div class="wed-test" id="resource1">
          <div class = "inner1">
            <h3 > <a class = "mat-raised-button mat-button mat-primary mat-primary-button trial-button" href = "https://gsrs.ncats.nih.gov/downloads/GinasExcelTools.zip">GSRSFind Excel tools</a></h3>
              <div class = "resource-desc"> GSRSFind is an add-in for Microsoft Excel that gives a user access to data in GSRS for both search/browse and creation/modification purposes. (Note: write access requires valid credentials!)
              GSRSFind can augment an existing spreadsheet with data from GSRS. For example, you can add chemical structures to a column of names or identifiers.
              </div>
            </div>
            </div>

            <div class="wed-test" id="resource2">
            <h3><a class = "mat-raised-button mat-button mat-primary mat-primary-button trial-button" style = "color:white" href ="https://gsrs.ncats.nih.gov/downloads/Substance%20Registration%20-%20October%202019.docx">User Registration Guide</a></h3>

            <div class = "resource-desc"> The GSRS user registration guide is comprehensive set of instructions for registering new substances of all types in GSRS.
           </div>
          </div>
  </div><div class = "test1">
            <div class="wed-test" id="resource3">
                <h3 class = "resource-link"> <a class = "resource-link mat-raised" href = "https://gsrs.ncats.nih.gov/downloads/GSRS_data_dictionary_11-20-19.xlsx">GSRS Data Dictionary</a></h3>
                <div class = "resource-desc">  The GSRS Data Dictionary is a comprehensive spreadsheet of all properties of the substance object used by GSRS. You will find descriptions,  relative paths, data types, and more for every property present in the GSRS substance model.
                  </div>
              </div>

                <div class="wed-test" id="resource4">
                    <h3 class = "resource-link">  <a class = "resource-link" href = "https://gsrs.ncats.nih.gov/downloads/GSRS_schema_10_30_19.json">GSRS JSON schema</a></h3>
                    <div class = "resource-desc">    This file contains a JSON formatted schema used by GSRS for recording substances to help visualize the substance object described by the GSRS Data Dictionary.
                      </div>
                  </div>

                    <div class="wed-test" id="resource5">
                        <h3 class = "resource-link"> <a class = "resource-link" href = "https://www.iso.org/standard/69697.html">ISO 11238 Standard</a></h3>
                        <div class = "resource-desc">       ISO 11238 is the standard that all substance data used by GSRS conforms to. ISO 11238 Substance Identification (SubID) provides an information model to define and identify substances within medicinal products or substances used for medicinal purposes.
                          </div>
                        </div>
                      </div>

    </mat-card>-->
    <!--<mat-card class = "home-card" >

        <div class="row" id="design">
                <h2  class = "home">Functional Design Studies</h2>
                <a href="https://gsrs.ncats.nih.gov//assets/files/DiederikDiscussiononNames.pdf"
                   class="link">Functional design requirements for substance
                    names, including domain, jurisdiction and language (pdf)</a>

                <div class = "spaced">&nbsp;Substance Names, Mandatory Sources and
                    Standardization</div>
                <br> <a href="https://gsrs.ncats.nih.gov/assets/files/FunctionalDesignSept2013.pdf"
                        class="link">Functional design for substance classes and
                groups according to the ISO 11238 scheme (pdf)</a>
                <div class = "spaced">&nbsp;Implementation of ISO/IDMP 11238 Substance
                    Standard and Movement towards a Global Ingredient Archival
                    System</div>
                <br> <a href="https://gsrs.ncats.nih.gov/assets/files/DiederikSubstanceFinal.pdf"
                        class="link">Functional design requirements: amlodipine,
                rosuvastatin zinc, and linaclotide examples (pdf)</a>

                <div class = "spaced">&nbsp;Functional Design ISO/ FDIS-IDMP DATABASE 11238</div>
                <br>
        </div>
      </mat-card><mat-card class = "home-card">


        <div class="row" id="cases">
            <div class="col-md-12">
                <h2 class ="home">Case Studies</h2>

                <div class="flex-row">
                    <div class="third">
                        <h3>Amlodipine Besilate</h3>
                        <div>
                            <img src="https://gsrs.ncats.nih.gov/assets/images/amlodipine-besilate.svg"
                                 alt="Amlodipine Besilate" class="img-responsive"/>
                        </div>
                        <div>
                            <p class = "para">Chemical Substance</p>
                            <p class = "para">Official Name</p>
                            <p class = "para">Salt / Parent Relationship</p>
                            <a href="https://gsrs.ncats.nih.gov/assets/files/Case%20Study%201%20-%20Amlodipine%20Besylate.pdf"
                               class="btn  mat-button mat-primary mat-flat-button  btn-primary">Learn more</a>
                        </div>
                    </div>
                    <div class="third">
                        <h3>Linaclotide</h3>
                        <div>
                            <img src="https://gsrs.ncats.nih.gov/assets/images/Linaclotide.png" alt="Linaclotide"
                                 class="img-responsive"/>
                        </div>
                        <div>


                            <p class = "para">Protein Substance</p>
                            <p class = "para">Amino Acid Sequence</p>
                            <p class = "para">Disulfide Linkages</p>
                            <a href="https://gsrs.ncats.nih.gov/assets/files/Case%20Study%204%20-%20Linaclotide.pdf"
                               class="btn  mat-button mat-primary mat-flat-button  btn-primary">Learn more</a> <br/>
                        </div>
                    </div>

                    <div class="third">
                        <h3>Insulin Degludec</h3>
                        <div>
                            <img src="https://gsrs.ncats.nih.gov/assets/images/Insulin%20Degludec.png" alt="Insulin Degludec"
                                 class="img-responsive"/>
                        </div>
                        <div>


                            <p class = "para">Protein Substance</p>
                            <p class = "para">C-Terminus Modification</p>
                            <p class = "para">Structural Modification</p>
                            <a href="https://gsrs.ncats.nih.gov/assets/files/Case%20Study%205%20-%20Insulin%20Degludec.pdf"
                               class="btn mat-button mat-primary mat-flat-button btn-primary">Learn more</a> <br/>
                        </div>
                    </div>
                </div>
                <div class="flex-row">
                    <div class="third">
                        <h3>Brentuximab Vedotin</h3>
                        <div>
                            <img src="https://gsrs.ncats.nih.gov/assets/images/Brentuximab%20Vedotin.png"
                                 alt="Brentuximab Vedotin" class="img-responsive"/>
                        </div>
                        <div>

                            <p class = "para">Protein Substance</p>
                            <p class = "para">Variable substitution</p>
                            <p class = "para">Modification</p>
                            <a href="https://gsrs.ncats.nih.gov/assets/files/Case%20Study%206%20-%20Brentuximab%20Vedotin.pdf"
                               class="btn  mat-button mat-primary mat-flat-button btn-primary">Learn more</a> <br/>
                        </div>
                    </div>

                    <div class="third">
                        <h3>Ritonavir</h3>
                        <div>
                            <img src="https://gsrs.ncats.nih.gov/assets/images/ritonavir.svg" alt="Form"
                                 class="img-responsive"/>
                        </div>
                        <div>

                            <p class = "para">Group 1 Specified Substance</p>
                            <p class = "para">Physical Forms</p>
                            <p class = "para">Physical Properties</p>
                            <a href="https://gsrs.ncats.nih.gov/assets/files/Case%20Study%203%20-%20Ritonavir.pdf"
                               class="btn  mat-button mat-primary mat-flat-button  btn-primary">Learn more</a>

                        </div>
                    </div>

                    <div class="third">
                        <h3>Simethicone</h3>
                        <div>
                            <img src="https://gsrs.ncats.nih.gov/assets/images/simethicone.png" alt="Simethicone"
                                 class="img-responsive"/>
                        </div>
                        <div>

                            <p class = "para">Group 1 Specified Substance</p>
                            <p class = "para">Multiple Substance Ingredient</p>
                            <p class = "para">Physical Properties</p>
                            <a href="https://gsrs.ncats.nih.gov/assets/files/Case%20Study%202%20-%20Simethicone.pdf"
                               class="btn  mat-button mat-primary mat-flat-button  btn-primary">Learn more</a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </mat-card>-->

    </div>
    </mat-sidenav-content>
    </mat-sidenav-container>