<div class="substance-selector-container">
    
    <app-substance-text-search
      [ngClass]="{'hidden': selectedSubstance}"
      (searchPerformed)="processSubstanceSearch($event)" [eventCategory]="eventCategory"
      [placeholder]="placeholder" source = "form-search">
    </app-substance-text-search>
    <div class = "undo" *ngIf = "previousSubstance">
        <button mat-icon-button (click)="revertEdit()" matTooltip ="revert to previously selected" class = "undo">
            <mat-icon aria-label="Revert substance edit" [ngClass]="{'hidden': selectedSubstance}" svgIcon="undo"></mat-icon>
        </button>
    </div>
    
    <div class="selected-substance" *ngIf="selectedSubstance" (mouseenter)="showOptions = true" (mouseleave)="showOptions = false">
      <div class="selected-substance-options" *ngIf="showOptions">
        <button mat-mini-fab (click)="editSelectedSubstance()">
            <mat-icon aria-label="Edit Selected Substance" svgIcon="edit" matTooltip = "change selection"></mat-icon>
        </button>
        <a *ngIf = "selectedSubstance.uuid" mat-mini-fab target="_blank" [routerLink]="['/substances', selectedSubstance.uuid]">
            <mat-icon aria-label="Open in new tab" svgIcon="open_in_new" matTooltip = "view details in a new tab"></mat-icon>
        </a>
          <button mat-mini-fab  (click)="delete()" matTooltip ="delete" class = "undo" >
              <mat-icon aria-label="delete selection" svgIcon="cancel"></mat-icon>
          </button>
      </div>
      <div class="section-header">{{header}}</div>
      <img class = "related-image" *ngIf = "selectedSubstance.uuid && !hideImage" appSubstanceImage [entityId]="selectedSubstance.uuid">
      <div>
        {{selectedSubstance._name}}
        <span class = 'code' *ngIf = "errorMessage === 'Not in database'"><br/>NOT IN DATABASE</span>
      </div>
    </div>
    <span class = 'code' *ngIf = "errorMessage === 'No substances found'"><br/>NO SUBSTANCES FOUND</span>
  </div>
