<div *ngIf="privateStageIndex > 0">
  <hr>
</div>
<div>
  <div class="form-container">
    <div *ngIf="stage.$$deletedCode" class="notification-backdrop">
      Deleted&nbsp;
      <button mat-icon-button matTooltip="Undo" (click)="undoDelete()">
        <mat-icon svgIcon="undo"></mat-icon>
      </button>
    </div>

    <div class="">
      <div class="button-delete">
        <button mat-icon-button matTooltip="Delete Stage {{(privateStageIndex+1)}}" (click)="confirmDeleteStage()">
          <mat-icon svgIcon="delete_forever"></mat-icon>
        </button>
      </div>
    </div>

    <div class="form-row">
      <!--
      <div class="delete-container">
        <button mat-icon-button matTooltip="Delete Stage {{(privateStageIndex+1)}}" (click)="confirmDeleteStage()">
          <mat-icon svgIcon="delete_forever"></mat-icon>
        </button>
      </div>
      -->
      <mat-form-field class="col-4-1"
        *ngIf="configSettingsDisplay['stageNumber'] || (configSettingsDisplay['stageNumber'] === undefined && true)">
        <input matInput placeholder="{{configTitleStage}} Number" [(ngModel)]="stage.stageNumber"
          name="stage.stageNumber" />
      </mat-form-field>

      <app-cv-input class="col-4-1"
        *ngIf="configSettingsDisplay['stageType'] || (configSettingsDisplay['stageType'] === undefined && showAdvancedSettings)"
        domain="SSG_STAGE_TYPE" title="Stage Type" name="stageType" [model]="stage.stageType"
        (valueChange)="stage.stageType = $event">
      </app-cv-input>

      <app-cv-input class="col-4-1"
        *ngIf="configSettingsDisplay['stageRole'] || (configSettingsDisplay['stageRole'] === undefined && showAdvancedSettings)"
        domain="SSG_STAGE_ROLE" title="Stage Role" name="stageRole" [model]="stage.stageRole"
        (valueChange)="stage.stageRole = $event">
      </app-cv-input>

      <mat-form-field class="col-4-1"
        *ngIf="configSettingsDisplay['stageEquipment'] || (configSettingsDisplay['stageEquipment'] === undefined && showAdvancedSettings)">
        <input matInput placeholder="Equipment" [(ngModel)]="stage.equipment" name="stage.equipment" />
      </mat-form-field>
    </div> <!-- form row -->

    <div class="form-row"
      *ngIf="configSettingsDisplay['stageComments'] || (configSettingsDisplay['stageComments'] === undefined && showAdvancedSettings)">
      <mat-form-field class="col-1-1">
        <textarea matInput placeholder="Comments" [(ngModel)]="stage.stageComments" name="stage.stageComments"
          rows="1"></textarea>
      </mat-form-field>
    </div> <!-- form row -->

    <!-- STARTING MATERIALS LIST BEGIN -->
    <div class="">
      <fieldset class="border">
        <legend class="border">
          <span matBadge="{{stage.startingMaterials.length}}" matBadgeOverlap="false">
            <span class=""><b>Starting Materials</b></span>
          </span>
        </legend>
        <!--
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <button mat-raised-button color="primary" (click)="addStartingMaterial(processIndex, siteIndex, stageIndex)"
        matTooltip="Add New Starting Material">
        <mat-icon svgIcon="add_circle_outline"></mat-icon>Add Starting Materials
      </button>
       -->
        <!-- Show More Details button-->
        <!--
    <button mat-button (click)="addStartingMaterial(processIndex, siteIndex, stageIndex)">
      <span class="colorgreen">Show More Details</span>
      <mat-icon>expand_more</mat-icon>
    </button>
      <br><br>
    -->
        <div class="marginbottom10px"
          *ngFor="let startingMaterial of stage.startingMaterials; let startingMaterialIndex = index">
          <app-ssg4m-starting-materials-form [startingMaterial]="startingMaterial" [processIndex]="processIndex"
            [siteIndex]="siteIndex" [stageIndex]="stageIndex" [startingMaterialIndex]="startingMaterialIndex"
            [showAdvancedSettings]="showAdvancedSettings">
          </app-ssg4m-starting-materials-form>
        </div>
        <!-- Another Add Button-->
        <!--  *ngIf="stage.startingMaterials && stage.startingMaterials.length > 0"-->
        <div class="divflex">
          <span class="middle-fill"></span>
          <button mat-stroked-button color="primary" class="button-add"
            (click)="addStartingMaterial(processIndex, siteIndex, stageIndex)">
            <mat-icon svgIcon="add_circle_outline"></mat-icon>Add Starting Material
          </button>
        </div>
      </fieldset>
    </div>
    <!-- STARTING MATERIALS LIST END -->


    <!-- PROCESSING MATERIALS LIST BEGIN -->
    <div class="">
      <fieldset class="border">
        <legend class="border">
          <span matBadge="{{stage.processingMaterials.length}}" matBadgeOverlap="false">
            <span class=""><b>{{configTitleProcessingMaterials}}</b></span>
          </span>
        </legend>
        <!--
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <button mat-raised-button color="primary" (click)="addProcessingMaterial(processIndex, siteIndex, stageIndex)"
        matTooltip="Add New {{configTitleProcessingMaterials}}">
        <mat-icon svgIcon="add_circle_outline"></mat-icon>Add {{configTitleProcessingMaterials}}
      </button>
      -->
        <!-- Show More Details button-->
        <!--
    <button mat-button (click)="addStartingMaterial(processIndex, siteIndex, stageIndex)">
      <span class="colorgreen">Show More Details</span>
      <mat-icon>expand_more</mat-icon>
    </button>
         <br><br>
    -->

        <div class="marginbottom10px"
          *ngFor="let processingMaterial of stage.processingMaterials; let processingMaterialIndex = index">
          <app-ssg4m-processing-materials-form [processingMaterial]="processingMaterial" [processIndex]="processIndex"
            [siteIndex]="siteIndex" [stageIndex]="stageIndex" [processingMaterialIndex]="processingMaterialIndex"
            [showAdvancedSettings]="showAdvancedSettings">
          </app-ssg4m-processing-materials-form>
        </div>
        <!-- Another Add Button-->
        <!--
        *ngIf="stage.processingMaterials && stage.processingMaterials.length > 0" style="display:flex;"
      -->
        <div class="divflex">
          <span class="middle-fill"></span>
          <button mat-stroked-button color="primary" class="button-add"
            (click)="addProcessingMaterial(processIndex, siteIndex, stageIndex)">
            <mat-icon svgIcon="add_circle_outline"></mat-icon>Add {{configTitleProcessingMaterials}}
          </button>
        </div>
      </fieldset>
    </div>
    <!-- PROCESSING MATERIALS LIST END -->


    <!-- RESULTING MATERIALS LIST BEGIN -->
    <div class="">
      <fieldset class="border">
        <legend class="border">
          <span matBadge="{{stage.resultingMaterials.length}}" matBadgeOverlap="false">
            <span class=""><b>Resulting Materials</b></span>
          </span>
        </legend>
        <!--
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button mat-raised-button color="primary" (click)="addResultingMaterial(processIndex, siteIndex, stageIndex)"
          matTooltip="Add New Resulting Material">
          <mat-icon svgIcon="add_circle_outline"></mat-icon>Add Resulting Materials
        </button>
         -->
        <!-- Show More Details button-->
        <!--
      <button mat-button (click)="addStartingMaterial(processIndex, siteIndex, stageIndex)">
      <span class="colorgreen">Show More Details</span>
      <mat-icon>expand_more</mat-icon>
      </button>
      -->
        <div class="marginbottom10px"
          *ngFor="let resultingMaterial of stage.resultingMaterials; let resultingMaterialIndex = index">
          <app-ssg4m-resulting-materials-form [resultingMaterial]="resultingMaterial" [processIndex]="processIndex"
            [siteIndex]="siteIndex" [stageIndex]="stageIndex" [resultingMaterialIndex]="resultingMaterialIndex"
            [showAdvancedSettings]="showAdvancedSettings">
          </app-ssg4m-resulting-materials-form>
        </div>
        <!-- Another Add Button-->
        <!--
           *ngIf="stage.resultingMaterials && stage.resultingMaterials.length > 0" style="display:flex;"
        -->
        <div class="divflex">
          <span class="middle-fill"></span>
          <button mat-stroked-button color="primary" class="button-add"
            (click)="addResultingMaterial(processIndex, siteIndex, stageIndex)">
            <mat-icon svgIcon="add_circle_outline"></mat-icon>Add Resulting Material
          </button>
        </div>
      </fieldset>
    </div>
    <!-- RESULTING MATERIALS LIST END -->

    <!-- CRITICAL PARAMETER/PROCESS CONTROLS LIST BEGIN -->
    <div class="">
      <fieldset class="border">
        <legend class="border">
          <b>Process Controls</b>&nbsp;
          <span matBadge="{{stage.criticalParameters.length}}" matBadgeOverlap="false"></span>
        </legend>
        <!--
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button mat-raised-button color="primary" (click)="addCriticalParameter(processIndex, siteIndex, stageIndex)"
          matTooltip="Add New Critical Parameter">
          <mat-icon svgIcon="add_circle_outline"></mat-icon>Add Process Controls
        </button>
        <br><br>
        -->
        <div class="marginbottom10px"
          *ngFor="let criticalParameter of stage.criticalParameters; let criticalParameterIndex = index">
          <app-ssg4m-critical-parameter-form [criticalParameter]="criticalParameter" [processIndex]="processIndex"
            [siteIndex]="siteIndex" [stageIndex]="stageIndex" [criticalParameterIndex]="criticalParameterIndex"
            [showAdvancedSettings]="showAdvancedSettings">
          </app-ssg4m-critical-parameter-form>
        </div>
        <!-- Another Add Button-->
        <div class="divflex">
          <span class="middle-fill"></span>
          <button mat-stroked-button color="primary" class="button-add"
            (click)="addCriticalParameter(processIndex, siteIndex, stageIndex)">
            <mat-icon svgIcon="add_circle_outline"></mat-icon>Add Process Control
          </button>
        </div>
      </fieldset>
      <br>
    </div>
    <!-- CRITICAL PARAMETER/PROCESS CONTROLS LIST END -->

  </div>
</div>