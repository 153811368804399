<div class="reference-form-container">
  <div *ngIf="reference.$$deletedCode" class="notification-backdrop">
    Deleted&nbsp;
    <button mat-icon-button matTooltip="Undo" (click)="undoDelete()">
      <mat-icon svgIcon="undo"></mat-icon>
    </button>
  </div>
  <div class="form-row">
    <app-cv-input required = 'true' class="source-type" domain = 'DOCUMENT_TYPE'  title = "Source Type" [model] = "reference.docType"
                  (valueChange)= "reference.docType = $event" name = "docType">
    </app-cv-input>
    <mat-form-field class="citation">
      <input matInput placeholder="Source Text/Citation" [(ngModel)]="reference.citation" required name="citation" />
    </mat-form-field>
    <div class="checkbox-container">
      <mat-checkbox [(ngModel)]="reference.publicDomain" name="publicDomain">
        Public Domain
      </mat-checkbox>
    </div>
    <div>
      <app-access-manager (accessOut)="updateAccess($event)" [access]="reference.access">
      </app-access-manager>
    </div>
  </div>
  <div *ngIf="!reference.$$deletedCode">
    <div class="form-row">
      <mat-form-field class="url">
        <input matInput placeholder="URL" [(ngModel)]="reference.url" name="refUrl" />
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Source Id" [(ngModel)]="reference.id" name="refId" />
      </mat-form-field>
      <app-tag-selector class="tags" (tagsUpdate)="updateTags($event)" [tags]="reference.tags"
        cvDomain="DOCUMENT_COLLECTION"></app-tag-selector>
    </div>
    <div class="form-row form-actions">
      <div *ngIf="reference.uuid && !hideDelete">
        <button mat-button (click)="deleteReference()">
          <span>Delete</span>&nbsp;
          <mat-icon svgIcon="delete_forever"></mat-icon>
        </button>
      </div>
      <div *ngIf="!reference.uploadedFile">
        <button mat-button ncatsFileSelect (selectedFile)="fileSelected($event)">
          <span>Upload Document</span>&nbsp;
          <mat-icon svgIcon="cloud_upload"></mat-icon>
        </button>
      </div>
      <div *ngIf="reference.uploadedFile">
        <button mat-button [matMenuTriggerFor]="documentMenu">
          Attachment
          <mat-icon svgIcon="attachment"></mat-icon>
        </button>
        <mat-menu #documentMenu="matMenu">
          <a mat-menu-item (click)="downloadDocument(reference.uploadedFile)">Download</a>
          <button mat-menu-item ncatsFileSelect (selectedFile)="fileSelected($event)">Replace</button>
          <button mat-menu-item (click)="reference.uploadedFile = null">Delete</button>
        </mat-menu>
      </div>
      <div class = "loading-container" *ngIf = "loading === true">
        <div class = "spinner-container">
            <mat-progress-spinner
            class="progress-spinner spinner"
            [color]="primary"
            mode = "indeterminate">
        </mat-progress-spinner>
        </div>
        
      <span>Uploading</span>
      </div>
      <div class = "loading-container" *ngIf = "loading === false && error === true">
          Error: There was a problem uploading this document
        </div>
      <div *ngIf="!reference.uuid">
          <button mat-button  (click)="showPrev = !showPrev" matTooltip = "use a previously submitted reference">
            <span>{{showPrev? 'Hide':'Use'}} Previous Substance Reference</span>&nbsp;
          </button>
        </div>
        <div *ngIf="reference.uuid && !reference.docType && !reference.citation">
            <button mat-button  (click)="openPreviousDialog()" matTooltip = "use a previously submitted reference">
              <span>{{showPrev? 'Hide':'Use'}} Previous Substance Reference</span>&nbsp;
            </button>
          </div>
      <span class="middle-fill"></span>
      <div *ngIf="reference.uuid">
        <app-apply-reference [subReferenceUuid]="reference.uuid"></app-apply-reference>
      </div>
    </div>
  </div>
  <div class = "form-row " *ngIf = "showPrev" >
    <app-previous-references class = "previous-ref" (selectedReference) = "fillReference($event)" ></app-previous-references>
  </div>
  <div class = "form-row">
    <app-audit-info [source] = "reference"></app-audit-info>
  </div>
</div>
