<div mat-dialog-content>
  {{data.message}}
</div>
<div mat-dialog-actions>
  <div *ngIf="((data.type !== null) && (data.type === 'home')); else noType">
    <button mat-button mat-dialog-close>Home</button>
  </div>
  <ng-template #noType>
    <button mat-button mat-dialog-close>No</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
  </ng-template>
</div>