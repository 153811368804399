<br>
<div *ngIf="impurities">
  <div *ngIf="impurities.id; else noRecord">
    <div class="details-container margintop90px">

      <mat-card class="bordergray">
        <mat-card-title>
          <div class="width300px">
            Impurities Details&nbsp;&nbsp;&nbsp;&nbsp;
            <!-- Edit GSRS record button -->
            <a [routerLink]="['/impurities', impurities.id, 'edit']" target="_blank" *ngIf="isAdmin"
              matTooltip='Edit GSRS Impurities Record'>
              <mat-icon svgIcon="edit"></mat-icon>
            </a>
            <!-- Copy Impurities -->
            <a mat-icon-button [routerLink]="['/impurities/register']" [queryParams]="{copy:impurities.id}"
              matTooltip='copy Impurities to new registration form' *ngIf="isAdmin">
              <mat-icon svgIcon="file_copy"></mat-icon>
            </a>
          </div>
          <div class="width60percent font11px">
            <span class="colorgray">Created By:</span> {{impurities.createdBy}}&nbsp;&nbsp;&nbsp;
            <span class="colorgray">Create
              Date:</span>{{impurities.creationDate|date: 'MM/dd/yyyy hh:mm:ss a'}}&nbsp;&nbsp;&nbsp;
            <span class="colorgray">Modified By:</span> {{impurities.modifiedBy}}&nbsp;&nbsp;&nbsp;
            <span class="colorgray">Modify Date:</span> {{impurities.lastModifiedDate|date: 'MM/dd/yyyy hh:mm:ss a'}}
          </div>
        </mat-card-title>

        <mat-card-content>
          <!-- Impurities Details -->
          <br>
          <div class="row">
            <div class="row-property">
              <div class="row-property-key">
                Source Type:
              </div>
              <div class="row-property-value">
                {{impurities.sourceType}}
              </div>
            </div>
            <div class="row-property">
              <div class="row-property-key">
                Source:
              </div>
              <div class="row-property-value">
                {{impurities.source}}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="row-property">
              <div class="row-property-key">
                Source ID:
              </div>
              <div class="row-property-value">
                {{impurities.sourceId}}
              </div>
            </div>
            <div class="row-property">
              <div class="row-property-key">
                Type:
              </div>
              <div class="row-property-value">
                {{impurities.type}}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="row-property">
              <div class="row-property-key">
                Spec Type:
              </div>
              <div class="row-property-value">
                {{impurities.specType}}
              </div>
            </div>
            <div class="row-property">
              <div class="row-property-key">
                Product ID:
              </div>
              <div class="row-property-value">
                {{impurities.productId}}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="row-property">
              <div class="row-property-key">
                Date Type:
              </div>
              <div class="row-property-value">
                {{impurities.dateType}}
              </div>
            </div>
            <div class="row-property">
              <div class="row-property-key">
                Date Type Date:
              </div>
              <div class="row-property-value">
                {{impurities.dateTypeDate|date: 'MM/dd/yyyy'}}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="row-property">
              <div class="row-property-key">
                Product/Substance Name:
              </div>
              <div class="row-property-value">
                {{impurities.productSubstanceName}}
              </div>
            </div>
            <div class="row-property">
              <div class="row-property-key">
                Submitter Name:
              </div>
              <div class="row-property-value">
                {{impurities.submitterName}}
              </div>
            </div>
          </div>


          <!-- Substance Names Lists -->
          <div class="margintop30px marginbottom30px"
            *ngFor="let subs of impurities.impuritiesSubstanceList; let i = index">

            <div *ngIf="i > 0">
              <br><br><br>
            </div>
            <div class="headerstyle title2 padleft20px">
              <b>{{(i + 1)}} of {{impurities.impuritiesSubstanceList.length}}.&nbsp;Impurity Substance</b>
            </div>

            <div class="bordergray">
              <mat-card>
                <!--
                <mat-card-title class="title2">
                  Substance Names ({{impurities.impuritiesSubstanceList.length}})
                </mat-card-title>
                -->
                <mat-card-content>

                  <div class="row-without-border">
                    <div class="row-property">
                      <div class="row-property-key">
                        Substance Name:
                      </div>
                      <div class="row-property-value">
                        <div>
                          <a [routerLink]="['/substances', subs.substanceUuid]" target="_blank">
                            <span class="colororange font16px fontbold">{{subs.substanceName}}</span>
                          </a>
                        </div>
                        <div *ngIf="subs.approvalID"><br>{{subs.approvalID}}</div>
                        <div *ngIf="subs.substanceUuid">
                          <img class="structure-img" appSubstanceImage [entityId]="subs.substanceUuid">
                        </div>
                      </div>
                    </div>
                    <div class="row-property">
                      <div class="row-property-key">
                        Assay Low:<br><br>
                        Assay High:<br><br>
                        Unit:<br><br>
                        Comments:<br>
                      </div>
                      <div class="row-property-value">
                        {{subs.low}}<br><br>
                        {{subs.high}}<br><br>
                        {{subs.unit}}<br><br>
                        {{subs.comments}}<br>
                      </div>
                    </div>
                  </div>

                  <!-- Impurities Test List -->
                  <!--
                    <div class="title2">Total Test ({{subs.impuritiesTestList.length}})</div>
                    -->

                  <div *ngFor="let impTest of subs.impuritiesTestList; let i = index">
                    <fieldset class="border">
                      <legend class="border">
                        Test {{(i + 1)}} of {{subs.impuritiesTestList.length}}
                        <span class="font11px">({{subs.substanceName}})</span>
                      </legend>

                      <div class="row">
                        <div class="row-property">
                          <div class="row-property-key">
                            Test:
                          </div>
                          <div class="row-property-value">
                            {{impTest.test}}
                          </div>
                        </div>
                        <div class="row-property">
                          <div class="row-property-key">
                            Test Type:
                          </div>
                          <div class="row-property-value">
                            {{impTest.testType}}
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="row-property">
                          <div class="row-property-key">
                            Test Description:
                          </div>
                          <div class="row-property-value">
                            {{impTest.testDescription}}
                          </div>
                        </div>
                        <div class="row-property">
                          <div class="row-property-key">
                            Comments:
                          </div>
                          <div class="row-property-value">
                            {{impTest.comments}}
                          </div>
                        </div>
                      </div>


                      <!-- Impurities Details List Begin -->
                      <div class="details-container margintop30px">
                        <mat-card class="bordergray">
                          <mat-card-title class="title2">
                            Impurities ({{impTest.impuritiesDetailsList.length}})
                          </mat-card-title>
                          <mat-card-content>
                            <table class="blueTable">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th class="width200px">Related Substance</th>
                                  <th>Source Impurity Name</th>
                                  <th>Impurity Type</th>
                                  <th>Limit Type</th>
                                  <th>Limit Value</th>
                                  <th>Unit</th>
                                  <th>Comments</th>
                                  <th>Identity Criteria</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let x of impTest.impuritiesDetailsList; let i = index">
                                  <td>{{i + 1}}</td>
                                  <td class="textaligncenter width200px">
                                    <div *ngIf="x.relatedSubstanceUuid">
                                      <div>
                                        <a [routerLink]="['/substances', x.relatedSubstanceUuid]"
                                          target="_blank">{{x.substanceName}}</a>
                                      </div>
                                      <div *ngIf="x.relatedSubstanceUnii"><br>{{x.relatedSubstanceUnii}}</div>
                                      <img class="structure-image" appSubstanceImage
                                        [entityId]="x.relatedSubstanceUuid">
                                    </div>
                                  </td>
                                  <td>{{ x.sourceImpurityName }}</td>
                                  <td>{{ x.impurityType }}</td>
                                  <td>{{ x.limitType }}</td>
                                  <td>{{ x.limitValue }}</td>
                                  <td>{{ x.unit }}</td>
                                  <td>{{ x.comments }}</td>
                                  <td>
                                    <div *ngFor="let idenCriteria of x.identityCriteriaList; let j = index">
                                      <span *ngIf="j > 0"><br></span>
                                      <b>Criteria Type:</b>&nbsp;{{idenCriteria.identityCriteriaType}}<br>
                                      <b>Amount Value:</b>&nbsp;{{idenCriteria.amountValue}}<br>
                                      <b>Unit:</b>&nbsp;{{idenCriteria.unit}}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </mat-card-content>
                        </mat-card>
                      </div>
                      <!-- Impurities Details List End -->



                      <!-- Unspecified Impurities Begin -->
                      <div class="details-container margintop30px">
                        <mat-card class="bordergray">
                          <mat-card-title class="title2">
                            Unspecified Impurities ({{impTest.impuritiesUnspecifiedList.length}})
                          </mat-card-title>
                          <mat-card-content>
                            <table class="blueTable">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Impurity Type</th>
                                  <th>Test Type</th>
                                  <th>Limit Type</th>
                                  <th>Limit Value</th>
                                  <th>Unit</th>
                                  <th>Comments</th>
                                  <th>Identity Criteria</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let x of impTest.impuritiesUnspecifiedList; let i = index">
                                  <td>{{i + 1}}</td>
                                  <td>{{ x.impurityType }}</td>
                                  <td>{{ x.testType }}</td>
                                  <td>{{ x.limitType }}</td>
                                  <td>{{ x.limitValue }}</td>
                                  <td>{{ x.unit }}</td>
                                  <td>{{ x.comments }}</td>
                                  <td>
                                    <div *ngFor="let idenCriteria of x.identityCriteriaList; let j = index">
                                      <span *ngIf="j > 0"><br></span>
                                      <b>Criteria Type:</b>&nbsp;{{idenCriteria.identityCriteriaType}}<br>
                                      <b>Amount Value:</b>&nbsp;{{idenCriteria.amountValue}}<br>
                                      <b>Unit:</b>&nbsp;{{idenCriteria.unit}}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </mat-card-content>
                        </mat-card>
                      </div>
                      <!-- Unspecified Impurities End -->


                    </fieldset> <!-- TEST -->
                    <br><br>
                  </div> <!-- Test List Loop -->


                  <!-- Impurities Residual Solvents Begin -->
                  <div class="details-container margintop30px">
                    <mat-card class="bordergray">
                      <mat-card-title class="title2">
                        Residual Solvents Impurities ({{subs.impuritiesResidualSolventsList.length}})
                      </mat-card-title>
                      <mat-card-content>
                        <table class="blueTable">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th class="width200px">Related Substance</th>
                              <th>Pharmaceutical Limit</th>
                              <th>Test Type</th>
                              <th>Limit Type</th>
                              <th>Limit Value</th>
                              <th>Unit</th>
                              <th>Comments</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let x of subs.impuritiesResidualSolventsList; let i = index">
                              <td>{{i + 1}}</td>
                              <td class="textaligncenter width200px">
                                <div *ngIf="x.relatedSubstanceUuid">
                                  <div>
                                    <a [routerLink]="['/substances', x.relatedSubstanceUuid]"
                                      target="_blank">{{x.substanceName}}</a>
                                  </div>
                                  <div *ngIf="x.relatedSubstanceUnii"><br>{{x.relatedSubstanceUnii}}</div>
                                  <img class="structure-image" appSubstanceImage [entityId]="x.relatedSubstanceUuid">
                                </div>
                              </td>
                              <td>{{ x.pharmaceuticalLimit }}</td>
                              <td>{{ x.testType }}</td>
                              <td>{{ x.limitType }}</td>
                              <td>{{ x.limitValue }}</td>
                              <td>{{ x.unit }}</td>
                              <td>{{ x.comments }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </mat-card-content>
                    </mat-card>
                  </div>
                  <!-- Impurities Residual Solvents End -->


                  <!-- Impurities Inorganic Begin -->
                  <div class="details-container margintop30px">
                    <mat-card class="bordergray">
                      <mat-card-title class="title2">
                        Inorganic Impurities ({{subs.impuritiesInorganicList.length}})
                      </mat-card-title>
                      <mat-card-content>
                        <table class="blueTable">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th class="width200px">Related Substance</th>
                              <th>Test Type</th>
                              <th>Limit Type</th>
                              <th>Limit Value</th>
                              <th>Unit</th>
                              <th>Comments</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let x of subs.impuritiesInorganicList; let i = index">
                              <td>{{i + 1}}</td>
                              <td class="textaligncenter width200px">
                                <div *ngIf="x.relatedSubstanceUuid">
                                  <div>
                                    <a [routerLink]="['/substances', x.relatedSubstanceUuid]"
                                      target="_blank">{{x.substanceName}}</a>
                                  </div>
                                  <div *ngIf="x.relatedSubstanceUnii"><br>{{x.relatedSubstanceUnii}}</div>
                                  <img class="structure-image" appSubstanceImage [entityId]="x.relatedSubstanceUuid">
                                </div>
                              </td>
                              <td>{{ x.testType }}</td>
                              <td>{{ x.limitType }}</td>
                              <td>{{ x.limitValue }}</td>
                              <td>{{ x.unit }}</td>
                              <td>{{ x.comments }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </mat-card-content>
                    </mat-card>
                  </div>
                  <!-- Impurities Residual Solvents End -->

                </mat-card-content> <!-- Substance List -->
              </mat-card>
            </div>


          </div> <!-- Substance LOOP -->



          <!-- Total Impurities Begin -->
          <div class="details-container">
            <mat-card class="bordergray">
              <mat-card-title class="title2">
                Total Impurities
              </mat-card-title>
              <mat-card-content>
                <div class="row">
                  <div class="row-property">
                    <div class="row-property-key">
                      Test Type:
                    </div>
                    <div class="row-property-value">
                      {{impurities.impuritiesTotal.testType}}
                    </div>
                  </div>
                  <div class="row-property">
                    <div class="row-property-key">
                      Limit Value:
                    </div>
                    <div class="row-property-value">
                      {{impurities.impuritiesTotal.limitValue}}
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="row-property">
                    <div class="row-property-key">
                      Amount Value:
                    </div>
                    <div class="row-property-value">
                      {{impurities.impuritiesTotal.amountValue}}
                    </div>
                  </div>
                  <div class="row-property">
                    <div class="row-property-key">
                      Limit Type:
                    </div>
                    <div class="row-property-value">
                      {{impurities.impuritiesTotal.limitType}}
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="row-property-2">
                    <div class="row-property-key-2">
                      Comments:
                    </div>
                    <div class="row-property-value-2">
                      {{impurities.impuritiesTotal.comments}}
                    </div>
                  </div>
                </div>

              </mat-card-content>
            </mat-card>
          </div>
          <!-- Total Impurities End -->

        </mat-card-content>
      </mat-card>

    </div>
  </div>

  <ng-template #noRecord>
    <br><br><br><br>
    <div class="colororange padtop50px padleft50px">There is no Impurities Data found.</div>
  </ng-template>

</div>
<br><br><br><br>