<br><br><br>
<mat-sidenav-container>

  <mat-sidenav mode="side" opened>
    <app-facets-manager (facetsParamsUpdated)="facetsParamsUpdated($event)" (facetsLoaded)="facetsLoaded($event)"
      [rawFacets]="rawFacets" configName="products" includeFacetSearch="true">
    </app-facets-manager>
  </mat-sidenav>

  <!-- Browse Product Content -->
  <mat-sidenav-content>
    <div class="side-nav-content">
      
      <!-- NARROW SEARCH SUGGESTIONS BEGIN -->
      <div class="narrow-search-suggestions-container"
        *ngIf="matchTypes && matchTypes.length > 0; else filterParameters">
        <div class="flex-row">
          <div class="mat-elevation-z2 narrow-search-suggestions" style="margin:auto; background-color: white;">
            <div>
              Would you like to restrict this search to a field?
            </div>&nbsp;
            <button mat-flat-button color="primary" [matMenuTriggerFor]="searchSuggestions" style="padding-right: 5px;"
              (menuOpened)="increaseOverlayZindex()" (menuClosed)="decreaseOverlayZindex()">
              <span>Fields </span>&nbsp;
              {{narrowSearchSuggestionsCount}}
              <mat-icon svgIcon="drop_down"></mat-icon>
            </button>
            <button mat-flat-button color="primary" (click)="clearFilters()">
              <span>RESET</span>
            </button>
            <button mat-icon-button color="primary" *ngIf="isSearchEditable" (click)="editAdvancedSearch()">
              <mat-icon svgIcon="edit"></mat-icon>
            </button>
            <mat-menu class="search-suggestions-container" #searchSuggestions="matMenu">
              <div class="narrow-search-dropdown" *ngFor="let matchType of matchTypes">
                <div *ngIf="((matchType) && (matchType === 'WORD' || matchType === 'FULL'))">
                  <div class="match-type">
                    {{matchType == 'WORD' ? 'Contains Match' : 'Exact Match'}}
                  </div>
                  <button mat-menu-item *ngFor="let suggestion of narrowSearchSuggestions[matchType]"
                    (click)="restricSearh(suggestion.luceneQuery)">
                    <div class="suggestion-display">
                      {{suggestion.displayField}}
                    </div>
                    <div class="suggestion-count">
                      {{suggestion.count}}
                    </div>
                  </button>
                </div>
              </div>
            </mat-menu>
          </div>
        </div>
        <div class="flex-row">
          <div class="mat-elevation-z2 narrow-search-suggestions"
            style="margin:auto; background-color: white; margin-top: 12px; ">
            For more options use the <a routerLink="/advanced-search" style="padding-left: 6px;"> Advanced Search</a>
          </div>
        </div>
      </div>
      <!-- Narrow Search Suggestion End -->

      <ng-template #filterParameters>
        <div class="search-parameters">
          <div class="mat-elevation-z2 selected-parameter" *ngIf="searchTerm">
            <div>
              <span class="capitalized font-medium-bold no-break">Search Query:</span>&nbsp;
              <span>{{searchTerm}}</span>
            </div>
            <div class="actions-container">
              <button mat-icon-button color="primary" *ngIf="isSearchEditable" (click)="editAdvancedSearch()">
                <mat-icon svgIcon="edit"></mat-icon>
              </button>
              <button mat-icon-button color="primary" (click)="clearSearch()">
                <mat-icon svgIcon="delete_forever"></mat-icon>
              </button>
            </div>
          </div>

          <!-- Display Facets selection -->
          <div class="mat-elevation-z2 selected-parameter" *ngFor="let facet of displayFacets">
            <div class='selected-container'>
              <span class="capitalized font-medium-bold selected-label">
                <mat-icon class="not-icon" svgIcon="not_interested" *ngIf="!facet.bool"></mat-icon>
                {{facet.type}}:
              </span>
              <span class="no-break selected-value">
                {{facet.val}}
              </span>
            </div>
            <div class="actions-container">
              <button mat-icon-button class="display-button" color="primary"
                (click)="facet.removeFacet(facet.type, facet.bool, facet.val)">
                <mat-icon class="display-icon" svgIcon="delete_forever"></mat-icon>
              </button>
            </div>
          </div>
          <button class="reset-facets-button" mat-raised-button color="primary" (click)="clearFilters()"
            *ngIf="(displayFacets && displayFacets.length > 0) || searchTerm">
            <span>RESET</span>
          </button>
        </div>
      </ng-template>

      <!-- Title, Export, Pagination -->
      <div class="controls-container">

        <div class="title-container">
          <span class="main-title">Browse Products</span>
        </div>

        <!-- Table, Full, and Title Views -->
        <mat-button-toggle-group [value]="view" (change)="updateView($event)">
          <mat-button-toggle value="table" matTooltip="Table View">
            <mat-icon svgIcon="list"></mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="cards" matTooltip="Full View">
            <mat-icon svgIcon="view_stream"></mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>

        <!-- Sort By -->
        <mat-form-field class="sort">
          <mat-label>Sort By</mat-label>
          <mat-select (selectionChange)="searchProducts()" [(ngModel)]="order">
            <mat-option *ngFor="let option of sortValues" [value]="option.value">
              {{option.display}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Export Product Button -->
        <div class="export" *ngIf="isAdmin">
          <button matTooltip="Export Search Results" mat-button (click)="export()"
            class="mat-button export-button mat-raised-button">
            <mat-icon svgIcon="get_app"></mat-icon> Export
          </button>
        </div>

        <div class="break"></div>
        <!-- Pagination -->
        <div class="full-paginator">
          <mat-paginator #paginator [length]="totalProducts" [pageIndex]="pageIndex" [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 50, 100]" [showFirstLastButtons]="true" (page)="changePage($event)">
          </mat-paginator>

          <div class="page-selector">
            <span class="page-label">Page: </span>
            <mat-form-field class="page-input" [ngClass]="{'bad-page':invalidPage}">
              <input matInput matTooltip="Type a page and press enter" [ngClass]="{'bad-page':invalidPage}"
                [value]="pageIndex + 1" #pagenum (keydown.enter)="customPage($event)"
                (input)="validatePageInput($event)? invalidPage = false: invalidPage = true"
                (focusout)="pagenum.value = pageIndex + 1; invalidPage = false">
            </mat-form-field>
            <span class="page-label"> of {{lastPage}}</span>
          </div>
        </div>

      </div> <!-- class=controls-container -->

      <div class="search-text">
        <!-- *** Search Any Text (TYPE AHEAD) *** -->
        <app-product-text-search class="top-search" placeholder="Search Any Text" styling="homePage"
          [searchValue]="searchValue" (searchPerformed)="processSubstanceSearch($event)" eventCategory="productSearch">
        </app-product-text-search>

        <!-- Search Any Text Text Box -->
        <!--
        <mat-form-field class="width350px font13px">
          <input matInput placeholder="Search Any Text" [(ngModel)]="privateSearchTerm" name="privateSearchTerm"
            size="50" />
        </mat-form-field>
        <button class="activate-search-button" mat-icon-button (click)="setSearchTermValue()">
          <mat-icon svgIcon="search"></mat-icon>
        </button>
        -->
      </div>

      <!-- ******** Display Content Details ******** -->
      <div [ngClass]="view">

        <!-- ***** TABLE VIEW BEGIN ***** -->
        <div class="table-view">
          <div class="responsive">

            <table mat-table [dataSource]="products" class="font12px" matSort (matSortChange)="sortData($event)"
              matSortDisableClear>

              <ng-container matColumnDef="productNDC">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Product ID </th>
                <td class="width120px" mat-cell *matCellDef="let product">
                  <!-- If fromtable is SPL, display SPL Details links -->
                  <span *ngIf="product.fromtable === 'SPL'">
                    <div class="padtop7px">
                      <a class="substance-name" [routerLink]="['/product-elist', product.productId]" target="_blank"
                        alt="View SPL Product Record" title="View SPL Product Record"><span
                          *ngIf="product.productNDC; else noProdNDC">{{product.productNDC}}</span>
                        <ng-template #noProdNDC>(NO ID)</ng-template>
                      </a>
                    </div>
                    <div class="padtop15px padbottom7px">
                      <ng-container *ngIf="product.productNDC !== null">
                        <a href="{{dailyMedUrl}}{{product.productNDC}}" target="_blank" alt="Go to DailyMed website"
                          title="Go to DailyMed website">
                          <img [src]="iconSrcPath">
                        </a>
                        <mat-icon class="small-icon" svgIcon="open_in_new" matTooltip="opens in new window">
                        </mat-icon>
                      </ng-container>
                    </div>
                  </span>

                  <!-- If fromtable is GSRS, display GSRS Details links -->
                  <span *ngIf="product.fromtable === 'GSRS'">
                    <a class="substance-name" [routerLink]="['/product', product.productId]" target="_blank"
                      alt="View Product Record"><span
                        *ngIf="product.productNDC; else noProdNDCSRS">{{product.productNDC}}</span>
                      <ng-template #noProdNDCSRS>(NO ID)</ng-template>
                    </a>
                    &nbsp;&nbsp;
                    <!-- Edit GSRS record button -->
                    <a [routerLink]="['/product', product.productId, 'edit']" target="_blank" *ngIf="isAdmin"
                      matTooltip='Edit Product Record'>
                      <mat-icon svgIcon="edit"></mat-icon>
                    </a>
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="productName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Name </th>
                <td mat-cell *matCellDef="let product">
                  <div *ngFor="let prodName of product.productNameAllList; let i = index">
                    {{prodName.productName}}
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="ingredientName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Ingredient Name (Active Ingredient) </th>
                <td valign="top" mat-cell *matCellDef="let product">
                  <ol class="olstyle">
                    <div *ngFor="let ing of product.productIngredientAllList; let indexIng = index">
                      <div *ngIf="ing.substanceName">
                        <div *ngIf="ing.ingredientType">
                          <div
                            *ngIf="(ing.ingredientType === 'ACTIVE INGREDIENT') || (ing.ingredientType === 'Active Ingredient')">
                            <li>
                              <a [routerLink]="['/substances', ing.substanceUuid]" target="_blank">{{ing.substanceName}}
                              </a>
                              <button mat-button class="structure-parameters"
                                (click)="openImageModal($event, ing.substanceUuid)">
                                <mat-icon style="padding:0px;margin:0px;">analytics</mat-icon>
                              </button>
                            </li>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ol>
                </td>
              </ng-container>

              <ng-container matColumnDef="labelerName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Labeler Name </th>
                <td mat-cell *matCellDef="let product"> {{product.labelerName}}
                  <div *ngFor="let prodComp of product.productCompanyAllList; let i = index">
                    {{prodComp.labelerName}}
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="country">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Country </th>
                <td mat-cell *matCellDef="let product">
                  <div *ngFor="let prodComp of product.productCompanyAllList; let i = index">
                    {{prodComp.countryWithoutCode}}
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let product"> {{product.status}} </td>
              </ng-container>

              <ng-container matColumnDef="productNameType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Type </th>
                <td mat-cell *matCellDef="let product"> {{product.productType}} </td>
              </ng-container>

              <!--
              <ng-container matColumnDef="ingredientType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Ingredient Type </th>
                <td mat-cell *matCellDef="let product">
                  <div *ngFor="let prodIng of product.productIngredientAllList; let i = index">
                    <div *ngIf="prodIng.substanceUuid">

                      <div *ngIf="(substance.uuid === prodIng.substanceUuid)">
                        {{prodIng.ingredientType}}
                      </div>

                    </div>
                  </div>
                </td>
              </ng-container>
              -->

              <ng-container matColumnDef="applicationNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Application Number </th>
                <td mat-cell *matCellDef="let product"><span
                    *ngIf="(product.appType && product.appNumber); else noAppType">
                    <!--
                  <a [routerLink]="['/applicationDarrtsDetails', product.appType, product.appNumber]"
                    target="_blank"><span class="approval-id">{{product.appTypeNumber}}</span>
                  </a>
                  -->
                    <a href="{{getAppTypeNumberUrl(product.appType, product.appNumber)}}" target="_blank"><span
                        class="approval-id">{{product.appTypeNumber}}</span>
                    </a>
                  </span>
                  <ng-template #noAppType>
                    <span class="approval-id">
                      {{product.appTypeNumber}}
                    </span>
                  </ng-template>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

          </div>
        </div> <!-- class="table-view" -->
        <!-- TABLE VIEW END -->

        <!-- CARD VIEW BEGIN -->
        <div class="cards-view" *ngIf="products && products.length; else noRecordProduct">
          <mat-card *ngFor="let product of products; let indexProd = index">
            <mat-card-title>
              <div>
                <span class="font13px">Product ID:</span>&nbsp;

                <!-- If fromtable is SPL, display SPL Details links -->
                <span *ngIf="product.fromtable === 'SPL'">
                  <a class="substance-name" [routerLink]="['/product-elist', product.productId]" target="_blank"><span
                      *ngIf="product.productNDC; else noProdNDC">{{product.productNDC}}</span>
                    <ng-template #noProdNDC>(NO ID)</ng-template>
                  </a>
                  <ng-container *ngIf="product.productNDC !== null">
                    &nbsp;&nbsp;
                    <a href="{{dailyMedUrl}}{{product.productNDC}}" target="_blank" alt="Go to DailyMed website"
                      title="Go to DailyMed website">
                      <img [src]="iconSrcPath">
                    </a>
                    <mat-icon class="small-icon margintop20px" svgIcon="open_in_new" matTooltip="opens in new window">
                    </mat-icon>
                  </ng-container>
                </span>

                &nbsp;&nbsp;&nbsp;
                <!-- If fromtable is GSRS, display GSRS Details links -->
                <span *ngIf="product.fromtable === 'GSRS'">
                  <a class="substance-name" [routerLink]="['/product', product.productId]" target="_blank"><span
                      *ngIf="product.productNDC; else noProdNDCSRS">{{product.productNDC}}</span>
                    <ng-template #noProdNDCSRS>(NO ID)</ng-template>
                  </a>

                  <!-- Edit GSRS record button -->
                  <a [routerLink]="['/product', product.productId, 'edit']" target="_blank" *ngIf="isAdmin"
                    matTooltip='Edit GSRS Product Record'>
                    <mat-icon svgIcon="edit"></mat-icon>
                  </a>
                </span>
              </div>

              <div class="center">
                <span class="font13px">Company Country:</span><span class="colororange font17px">&nbsp;
                  <!--{{product.countryWithoutCode}}-->
                  <span *ngFor="let prodComp of product.productCompanyAllList; let indexComp = index">
                    <span *ngIf="prodComp.countryWithoutCode">
                      <span *ngIf="indexComp > 0">|</span>
                      {{prodComp.countryWithoutCode}}
                    </span>
                  </span>
                </span>
              </div>

              <div>
                <span class="font13px">Application:</span>&nbsp;
                <span *ngIf="(product.appType && product.appNumber); else noAppType">
                  <!--
                  <a [routerLink]="['/applicationDarrtsDetails', product.appType, product.appNumber]"
                    target="_blank"><span class="approval-id">{{product.appTypeNumber}}</span>
                  </a>
                  -->
                  <a href="{{getAppTypeNumberUrl(product.appType, product.appNumber)}}" target="_blank"><span
                      class="approval-id">{{product.appTypeNumber}}</span>
                  </a>
                </span>
                <ng-template #noAppType>
                  <span class="approval-id">
                    {{product.appTypeNumber}}
                  </span>
                </ng-template>
              </div>
            </mat-card-title>

            <mat-card-content>

              <div class="row">
                <div class="row-property">
                  <div class="row-property-key">
                    Product Name:
                  </div>
                  <div class="row-property-value">
                    <div *ngFor="let prodName of product.productNameAllList; let indexName = index">
                      <span class="colorlightblue font14px"><b>{{prodName.productName}}</b></span>
                    </div>
                  </div>
                </div>
                <div class="row-property">
                  <div class="row-property-key">
                    Product Type:
                  </div>
                  <div class="row-property-value">
                    {{product.productType}}
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="row-property">
                  <div class="row-property-key">
                    Nonproprietary Name:
                  </div>
                  <div class="row-property-value">
                    {{product.nonProprietaryName}}
                  </div>
                </div>
                <div class="row-property">
                  <div class="row-property-key">
                    Route of Admin:
                  </div>
                  <div class="row-property-value">
                    {{product.routeName}}
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="row-property">
                  <div class="row-property-key">
                    Labeler Name (DUNS):
                  </div>
                  <div class="row-property-value">
                    <div *ngFor="let prodComp of product.productCompanyAllList; let indexComp = index">
                      {{prodComp.labelerName}}
                      <span *ngIf="prodComp.labelerDuns">&nbsp;({{prodComp.labelerDuns}})</span>
                    </div>
                  </div>
                </div>
                <div class="row-property">
                  <div class="row-property-key">
                    City, State, Country:
                  </div>
                  <div class="row-property-value">
                    <div *ngFor="let prodComp of product.productCompanyAllList; let indexComp = index">
                      {{prodComp.city}}
                      <span *ngIf="prodComp.state">,</span>
                      {{prodComp.state}}
                      <span *ngIf="prodComp.countryWithoutCode">,</span>
                      {{prodComp.countryWithoutCode}}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="row-property">
                  <div class="row-property-key">
                    Dosage Form:
                  </div>
                  <div class="row-property-value">
                    <div *ngFor="let ing of product.productIngredientAllList; let indexIng = index">
                      <span *ngIf="indexIng == 0">
                        {{ing.dosageFormName}}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row-property">
                  <div class="row-property-key">
                    Status:
                  </div>
                  <div class="row-property-value">
                    {{product.status}}
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="isAdmin">
                <div class="row-property">
                  <div class="row-property-key">
                    Create By:
                  </div>
                  <div class="row-property-value">
                    {{product.createdBy}}
                  </div>
                </div>
                <div class="row-property">
                  <div class="row-property-key">
                    Edited By:
                  </div>
                  <div class="row-property-value">
                    {{product.modifiedBy}}
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="isAdmin">
                <div class="row-property">
                  <div class="row-property-key">
                    Create Date:
                  </div>
                  <div class="row-property-value">
                    {{product.creationDate|date: 'MM/dd/yyyy hh:mm:ss a'}}
                  </div>
                </div>
                <div class="row-property">
                  <div class="row-property-key">
                    Edit Date:
                  </div>
                  <div class="row-property-value">
                    {{product.lastModifiedDate|date: 'MM/dd/yyyy hh:mm:ss a'}}
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="row-property">
                  <div class="row-property-key">
                    Substance Name (Active):
                  </div>
                  <div class="row-property-value">

                    <!-- Display Active Substance -->
                    <ol class="olstyle">

                      <div *ngFor="let prodIngred of product.productIngredientAllList; let indexActiveIngred = index">

                        <span *ngIf=" prodIngred.ingredientType">
                          <span *ngIf="prodIngred.ingredientType === 'Active Ingredient'; else otherIngredType">

                            <span *ngIf="prodIngred.substanceName">
                              <li>
                                <a [routerLink]="['/substances', prodIngred.substanceUuid]"
                                  target="_blank">{{prodIngred.substanceName}}
                                </a>
                                <button mat-button class="structure-parameters"
                                  (click)="openImageModal($event, prodIngred.substanceUuid)">
                                  <mat-icon style="padding:0px;margin:0px;">analytics</mat-icon>
                                </button>
                              </li>
                            </span>

                          </span>
                        </span>

                      </div> <!-- repeat -->
                    </ol>

                  </div>
                </div>


                <div class="row-property">
                  <div class="row-property-key">
                    Substance Name (Inactive):
                  </div>
                  <div class="row-property-value">
                    <!-- Display Other Substance -->
                    <ol class="olstyle">
                      <div *ngFor="let prodIngred of product.productIngredientAllList; let indexIngred = index">

                        <!-- If Ingredient Type is not null -->
                        <div *ngIf="prodIngred.ingredientType; else noIngredType">

                          <!-- If Not Active Ingredient, display Substance Name and Ingredient Type-->
                          <div *ngIf="prodIngred.ingredientType !== 'Active Ingredient'">

                            <div *ngIf="prodIngred.substanceName">
                              <li class="">
                                <a [routerLink]="['/substances', prodIngred.substanceUuid]"
                                  target="_blank">{{prodIngred.substanceName}}
                                </a>
                                <button mat-button class="structure-parameters"
                                  (click)="openImageModal($event, prodIngred.substanceUuid)">
                                  <mat-icon style="padding:0px;margin:0px;">analytics</mat-icon>
                                </button>
                              </li>
                            </div>

                            <div
                              *ngIf="(prodIngred.ingredientType !== 'Active Ingredient') && (prodIngred.ingredientType !== 'Inactive Ingredient')">
                              <span class="colorgreen">({{ prodIngred.ingredientType }})
                              </span>
                            </div>
                          </div>

                        </div>

                        <!-- No Ingredient Type -->
                        <ng-template #noIngredType>
                          <!-- if No Ingredient Type, but have Substance Name, display here. -->
                          <div *ngIf="prodIngred.substanceName">
                            <li class="">
                              <a [routerLink]="['/substances', prodIngred.substanceUuid]"
                                target="_blank">{{prodIngred.substanceName}}
                              </a>
                            </li>
                          </div>
                        </ng-template>

                      </div> <!-- repeat -->
                    </ol>

                  </div>
                </div>

              </div>

            </mat-card-content>

          </mat-card>
        </div> <!-- class=cards -->
      </div> <!-- [ngClass]=view -->

      <!-- Pagination at the bottom of the page -->
      <div class="controls-container">
        <div class="full-paginator">
          <mat-paginator #paginator [length]="totalProducts" [pageIndex]="pageIndex" [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 50, 100]" [showFirstLastButtons]="true" (page)="changePage($event)">
          </mat-paginator>

          <div class="page-selector">
            <span class="page-label">Page: </span>
            <mat-form-field class="page-input" [ngClass]="{'bad-page':invalidPage}">
              <input matInput matTooltip="Type a page and press enter" [ngClass]="{'bad-page':invalidPage}"
                [value]="pageIndex + 1" #pagenum (keydown.enter)="customPage($event)"
                (input)="validatePageInput($event)? invalidPage = false: invalidPage = true"
                (focusout)="pagenum.value = pageIndex + 1; invalidPage = false">
            </mat-form-field>
            <span class="page-label"> of {{lastPage}}</span>
          </div>
        </div>
      </div>

      <ng-template #noRecordProduct>
        <!-- No Product Found. -->
        <!--
      <div class="padleft10px colorred">
        <br>
        No Product records found. Please Reindex to see the Product records here.
      </div>
      -->
      </ng-template>

    </div> <!-- class=side-nav-content -->
  </mat-sidenav-content>
</mat-sidenav-container>