<div class="login-container">
  <mat-card *ngIf="isLoaded">
    <mat-card-title>
      Login
    </mat-card-title>
    <mat-card-content>
      <form class="login-form" [formGroup]="loginForm">
        <mat-form-field>
          <input matInput placeholder="Username" formControlName="username" autofocus>
        </mat-form-field>

        <mat-form-field>
            <input type="password" matInput placeholder="Password" formControlName="password">
        </mat-form-field>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <button color="primary" mat-flat-button [disabled]="loginForm.invalid || isLoading" (click)="login()">Login</button>
    </mat-card-actions>
  </mat-card>
</div>
