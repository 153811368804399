<!-- Buttons: delete, review -->
<div class="marginbottomminus17px padleft220px">
  <button mat-icon-button [ngStyle]="{'color':'orangered'}" [disabled]="totalIngredient == 1"
    (click)="confirmDeleteProductIngredient(prodComponentIndex, prodLotIndex, prodIngredientIndex)"
    matTooltip="Delete Ingredient Details {{prodIngredientIndex + 1}}">
    <mat-icon [class.disabled]="totalIngredient == 1">delete_forever</mat-icon>
  </button>
  &nbsp;&nbsp;
  <button mat-icon-button [ngStyle]="{'color':'orangered'}" (click)="copyProductIngredient()"
    matTooltip="Copy Ingredient Details {{prodIngredientIndex + 1}} to New Ingredient">
    <mat-icon>file_copy</mat-icon>
  </button>
  <!--
    &nbsp;&nbsp;
    <button mat-icon-button [ngStyle]="{'color':'orangered'}" (click)="confirmReviewIngredient()"
      matTooltip="Click to Confirm Ingredient Details {{prodIngredientIndex + 1}} data has been Reviewed">
      <mat-icon>check_circle</mat-icon>
    </button>
    <span *ngIf="ingredient.reviewDate" class="colorgray font12px">Reviewed
      By:{{ingredient.reviewedBy}}&nbsp;{{ingredient.reviewDate|date: 'MM/dd/yyyy hh:mm:ss a'}}
    </span>
  -->
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <span class="font11px textalignright" *ngIf="ingredient">
    <span *ngIf="ingredient.id">
      <span class="colorgray">Created By:</span> {{ingredient.createdBy}}&nbsp;&nbsp;&nbsp;
      {{ingredient.createDate|date: 'MM/dd/yyyy hh:mm:ss a'}}&nbsp;&nbsp;&nbsp;
      <span class="colorgray">Modified By:</span> {{ingredient.modifiedBy}}&nbsp;&nbsp;&nbsp;
      {{ingredient.lastModifiedDate|date: 'MM/dd/yyyy hh:mm:ss a'}}
    </span>
  </span>
</div>


<!-- Display Ingredient -->
<fieldset class="border">
  <legend class="border">Ingredient Details&nbsp;<b><span
        class="colorgreen font17px">{{prodIngredientIndex + 1}}</span></b>
  </legend>

  <div class="divflexrow">
    <!-- Ingredient Name -->
    <div class="col-1-4 flex-column related-substance">
      <div class="related-substance-border">
        <app-substance-search-selector (selectionUpdated)="ingredientNameUpdated($event)"
          (showMessage)="showMessageIngredientName($event)" (searchValueOut)="searchValueOutChange($event)"
          eventCategory="ingredName" placeholder="Ingredient Name" header="Ingredient Name" [subuuid]="substanceUuid">
        </app-substance-search-selector>

        <div *ngIf="ingredientNameMessage" class="colorred font12px">
          {{ingredientNameMessage}}
        </div>

        <!-- Display Ingredient Name Details -->
        <div class="padall5px fontfamilyhelvetica">
          <div *ngIf="ingredientName">
            <span class="colormaroon"><b>{{ingredientName}}</b></span>
            <br>
            <!-- Display Active Moiety -->
            <div *ngFor="let active of ingredientNameActiveMoiety; let activeIndex = index">
              {{active}} <span class="colorgray font11px">(Active Moiety)</span>
            </div>
          </div>
          <div *ngIf="ingredient.substanceKey">
            <span class="colororange">{{ingredient.substanceKey}}</span><span class="colorgray font11px">
              ({{ingredient.substanceKeyType}})</span>
            <br>
            <button mat-icon-button color="primary" (click)="confirmDeleteIngredientName()"
              matTooltip="Delete Ingredient Name {{prodIngredientIndex + 1}}">
              <mat-icon>delete_forever</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Basis of Strength -->
    <div class="col-1-4 flex-column related-substance">
      <div class="related-substance-border">
        <app-substance-search-selector (selectionUpdated)="basisOfStrengthUpdated($event)"
          (showMessage)="showMessageBasisOfStrength($event)" (searchValueOut)="searchValueBasisOutChange($event)"
          eventCategory="substanceRelationshipRelatedSub" placeholder="Basis Of Strength" header="Basis Of Strength" [subuuid]="basisOfStrengthSubstanceUuid">
        </app-substance-search-selector>

        <div *ngIf="basisOfStrengthMessage" class="colorred font12px">
          {{basisOfStrengthMessage}}
        </div>

        <!-- Display Basis of Strength Details -->
        <div class="padall5px fontfamilyhelvetica">
          <div *ngIf="basisOfStrengthIngredientName">
            <span class="colormaroon"><b>{{basisOfStrengthIngredientName}}</b></span>
            <br>
            <!-- Display Active Moiety -->
            <div *ngFor="let active of basisOfStrengthActiveMoiety; let activeIndex = index">
              {{active}} <span class="colorgray font11px">(Active Moiety)</span>
            </div>
          </div>

          <div *ngIf="ingredient.basisOfStrengthSubstanceKey">
            <span class="colororange">{{ingredient.basisOfStrengthSubstanceKey}}</span><span class="colorgray font11px">
              ({{ingredient.basisOfStrengthSubstanceKeyType}})</span>
            <br>
            <button mat-icon-button color="primary" (click)="confirmDeleteBasisOfStrength()"
              matTooltip="Delete Basis of Strength {{prodIngredientIndex + 1}}">
              <mat-icon>delete_forever</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--
    <mat-form-field class="col-1-4">
      <mat-label>Grade</mat-label>
      <mat-select matNativeControl [(ngModel)]="ingredient.grade" name="grade">
        <mat-option *ngFor="let grade of gradeList" [value]="grade.value">
          {{grade.display}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    -->
    <app-cv-input class="col-1-4" domain="PROD_GRADE" title="Grade" name="grade" [model]="ingredient.grade"
      (valueChange)="ingredient.grade = $event">
    </app-cv-input>

    <div>
      <label>Ingredient Location</label><br>
      <span *ngFor="let data of locationList; let i=index">
        <mat-checkbox class="font11px" #checkBox [value]="data.value" [(ngModel)]="data.checked" name="data.value" (change)="setSelectedIngredientLocation(data, checkBox)">
          {{data.value}}
        </mat-checkbox>
        &nbsp;
      </span>
    </div>

    <!--
    <mat-form-field class="col-1-4">
      <div *ngFor="let data of locationList; let i=index">
        <mat-checkbox [(ngModel)]="checked" name="data.name">
          {{ data.name }}
        </mat-checkbox>
      </div>
    </mat-form-field>
    -->
    <!--
    <mat-form-field class="col-1-4">
      <input matInput placeholder="Ingredient Location" [(ngModel)]="ingredient.ingredientLocation"
        name="ingredientLocation" />
    </mat-form-field>
  -->

  </div> <!-- row-->

  <div class="form-row margintop10px">
    <app-cv-input class="col-1-4" domain="INGREDIENT_TYPE" title="Ingredient Type" name="ingredientType"
      [model]="ingredient.ingredientType" (valueChange)="ingredient.ingredientType = $event">
    </app-cv-input>
    <!--
    <mat-form-field class="col-1-4">
      <mat-label>Ingredient Type</mat-label>
      <mat-select matNativeControl [(ngModel)]="ingredient.ingredientType" name="ingredientType">
        <mat-option *ngFor="let ingredientType of ingredientTypeList" [value]="ingredientType.value">
          {{ingredientType.display}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    -->

    <mat-form-field class="col-1-4">
      <input matInput placeholder="Average" [(ngModel)]="ingredient.average" name="average" />
    </mat-form-field>

    <mat-form-field class="col-half-4">
      <input matInput placeholder="Low" [(ngModel)]="ingredient.low" name="low" />
    </mat-form-field>

    <mat-form-field class="col-half-4">
      <input matInput placeholder="High" [(ngModel)]="ingredient.high" name="high" />
    </mat-form-field>

    <app-cv-input class="col-1-4" domain="PROD_UNIT" title="Unit" name="unit" [model]="ingredient.unit"
      (valueChange)="ingredient.unit = $event">
    </app-cv-input>

    <!--
    <mat-form-field class="col-1-4">
      <mat-label>Unit</mat-label>
      <mat-select matNativeControl [(ngModel)]="ingredient.unit" name="unit">
        <mat-option *ngFor="let unit of unitList" [value]="unit.value">
          {{unit.display}}
        </mat-option>
      </mat-select>
    </mat-form-field>
     -->
  </div>

  <div class="form-row">
    <app-cv-input class="col-1-4" domain="PROD_RELEASE_CHARACTERISTIC" title="Release Characteristics"
      name="releaseCharacteristic" [model]="ingredient.releaseCharacteristic"
      (valueChange)="ingredient.releaseCharacteristic = $event">
    </app-cv-input>
    <!--
    <mat-form-field class="col-1-4">
      <mat-label>Release Characteristics</mat-label>
      <mat-select matNativeControl [(ngModel)]="ingredient.releaseCharacteristic" name="releaseCharacteristic">
        <mat-option *ngFor="let releaseCharacteristic of releaseCharacteristicList"
          [value]="releaseCharacteristic.value">
          {{releaseCharacteristic.display}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    -->

    <mat-form-field class="col-1-4">
      <input matInput placeholder="Manufacturer" [(ngModel)]="ingredient.manufacturer" name="manufacturer" />
    </mat-form-field>

    <mat-form-field class="col-1-4">
      <input matInput placeholder="Lot No" [(ngModel)]="ingredient.ingredLotNo" name="ingredLotNo" />
    </mat-form-field>

    <mat-form-field class="col-1-4">
      <input matInput placeholder="Notes" [(ngModel)]="ingredient.notes" name="notes " />
    </mat-form-field>
  </div> <!-- row-->

</fieldset>
<br>