<div *ngIf="impurities">
  <div class="top-fixed">
    <div class="actions-container">

      <button mat-flat-button color="primary" (click)="showJSON()">Show JSON</button>
      <button mat-flat-button class="hide-show-messages" (click)="toggleValidation()" [disabled]="isLoading"
        *ngIf="validationMessages && validationMessages.length > 0">
        {{showSubmissionMessages ? 'Hide' : 'Show'}} messages
        <mat-icon [svgIcon]="showSubmissionMessages ? 'chevron_up' : 'chevron_down'"></mat-icon>
      </button>
      <span class="middle-fill"></span>


      <!-- Register and View Application buttons -->
      <span *ngIf="impurities">
        <span *ngIf="impurities.id">
          <button mat-flat-button color="primary" [routerLink]="['/impurities/register']"
            matTooltip='Register Impurities'>
            Register New Impurities
          </button>&nbsp;&nbsp;&nbsp;

          <a mat-flat-button color="primary" [routerLink]="['/impurities', impurities.id]" target="_blank"
            matTooltip='View this Impurity Record (opens in a new window)'>
            <mat-icon class="small-icon" svgIcon="open_in_new" matTooltip="opens in new window"></mat-icon>
            View Impurities
          </a>&nbsp;&nbsp;&nbsp;

          <span *ngIf="isAdmin">
            <button mat-flat-button color="primary" (click)="confirmDeleteImpurities()"
              matTooltip='Delete this Impurity'>
              Delete
            </button>&nbsp;&nbsp;&nbsp;
          </span>

        </span>
      </span>

      <!-- Validate and Submit Button -->
      &nbsp;
      <button mat-flat-button color="warn" (click)="validate()">Validate and Submit</button>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <button mat-flat-button color="primary" [routerLink]="['/home']" matTooltip='Close this window'>Close</button>

    </div>

    <!-- Validation Message Display -->
    <div
      [ngClass]="{'submission-messages': true, collapsed: !showSubmissionMessages, expanded: showSubmissionMessages}">
      <div class="submission-message" *ngIf="submissionMessage">
        {{submissionMessage}}
      </div>

      <!-- Validation Message Display and Submit Button -->
      <div *ngIf="validationMessages">
        <span *ngIf=" validationMessages.length > 0">
          <div class="submission-message" *ngIf='!serverError && !approving'>
            Please correct or dismiss the following errors and submit again:
          </div>
          <div class="validation-message" *ngFor="let message of validationMessages; index as i">
            <div class="message-type" [ngClass]="message.messageType == 'ERROR' ? 'error-message' : 'warning-message'">
              {{message.messageType}}</div>
            <div class="message">{{message.message}}<span *ngFor="let link of message.links"><br /><a [href]="link.href"
                  target="_blank">{{link.text}}</a></span></div>
            <button mat-icon-button matTooltip="Dismiss" *ngIf="message.messageType != 'ERROR' && !approving"
              (click)="dismissValidationMessage(i)">
              <mat-icon svgIcon="cancel"></mat-icon>
            </button>
          </div>
        </span>

        <div class="dismiss-container">
          <span class="middle-fill"></span>
          <button mat-flat-button color="primary" (click)="submit()"
            [disabled]="isLoading || (this.validationResult === false)">{{validationMessages && validationMessages.length > 0?'Dismiss All and ':''}}
            Submit</button>
        </div>
      </div>
      <!-- Validation Message and Submit Button -->

    </div>
  </div>

  <!-- Display Form Fields -->

  <div class="form-content-container mat-form-field-style">
    <div class="scrollable-container">
      <div class="cards-container">
        <div class="">
          <div class="title_box">
            <div class="title">
              {{title}}
            </div>

            <div class="font11px" *ngIf="impurities.id">
              <br>
              <div class="font11px">
                <span class="colorgray">Created By:</span> {{impurities.createdBy}}&nbsp;&nbsp;&nbsp;
                <span class="colorgray">Create Date:</span>
                {{impurities.creationDate|date: 'MM/dd/yyyy hh:mm:ss a'}}&nbsp;&nbsp;&nbsp;
                <span class="colorgray">Modified By:</span> {{impurities.modifiedBy}}&nbsp;&nbsp;&nbsp;
                <span class="colorgray">Modify Date:</span>
                {{impurities.lastModifiedDate|date: 'MM/dd/yyyy hh:mm:ss a'}}
              </div>
            </div>
          </div> <!-- title_box-->

          <mat-card class="bordergray" *ngIf="impurities">
            <mat-card-content>
              <!-- Impurities Form -->

              <div class="form-row">
                <app-cv-input class="col-6-1" domain="IMPURITIES_SOURCE_TYPE" title="Source Type" name="sourceType"
                  [model]="impurities.sourceType" (valueChange)="impurities.sourceType = $event">
                </app-cv-input>

                <app-cv-input class="col-6-1" domain="IMPURITIES_SOURCE" title="Source" name="source"
                  [model]="impurities.source" (valueChange)="impurities.source = $event">
                </app-cv-input>

                <mat-form-field class="col-6-1">
                  <input matInput placeholder="Source ID" [(ngModel)]="impurities.sourceId" name="sourceId" />
                </mat-form-field>

                <app-cv-input class="col-6-1" domain="IMPURITIES_TYPE" title="Type" name="type"
                  [model]="impurities.type" (valueChange)="impurities.type = $event">
                </app-cv-input>

                <mat-form-field class="col-6-1">
                  <input matInput placeholder="Product ID" [(ngModel)]="impurities.productId" name="productId" />
                </mat-form-field>

                <app-cv-input class="col-6-1" domain="IMPURITIES_SPEC_TYPE" title="Spec Type" name="specType"
                  [model]="impurities.specType" (valueChange)="impurities.specType = $event">
                </app-cv-input>
              </div>

              <!-- Second Row -->
              <div class="form-row">
                <mat-form-field class="col-6-2">
                  <input matInput placeholder="Submitter Name" [(ngModel)]="impurities.submitterName" name="submitterName" />
                </mat-form-field>

                <mat-form-field class="col-6-2">
                  <input matInput placeholder="Product/Substance Name" [(ngModel)]="impurities.productSubstanceName"
                    name="companyProductName" />
                </mat-form-field>

                <app-cv-input class="col-6-1" domain="IMPURITIES_DATE_TYPE" title="Date Type" name="dateType"
                  [model]="impurities.dateType" (valueChange)="impurities.dateType = $event">
                </app-cv-input>

                <mat-form-field class="col-6-1">
                  <!--
                  <input matInput placeholder="Date Type Date" [(ngModel)]="impurities.dateTypeDate"
                    name="dateTypeDate" />
                  -->
                  <input matInput placeholder="Date Type Date" name="dateTypeDate" (ngModelChange)="updateDateTypeDate($event)" [ngModel]="impurities.dateTypeDate |date:'yyyy-MM-dd'" #dateT="ngModel" type="date"/>
                </mat-form-field>

              </div>
              <!-- TOP SECTION END  -->


              <!-- IMPURITIES SUBSTANCE LIST BEGIN -->
              <br>
              <mat-expansion-panel [expanded]="panelExpanded" class="panel-style bordergray">
                <mat-expansion-panel-header class="">
                  <mat-panel-title class="margintop12px">
                    <div class="divflex">
                      <div class="margintop5px">
                        <span class="titleblue"><b>Substance</b></span>&nbsp;
                      </div>
                      <div class="margintop15px custom-badge" matBadge="{{impurities.impuritiesSubstanceList.length}}"
                        matBadgeColor="warn" matBadgeSize="medium" matBadgeOverlap="false" matBadgePosition="top">
                      </div>
                    </div>
                  </mat-panel-title>
                  <mat-panel-description class="marginleft25px">
                    <div>
                      <button mat-raised-button color="primary" (click)="addNewImpuritiesSubstance($event)"
                        matTooltip="Add New Test">
                        <mat-icon svgIcon="add_circle_outline"></mat-icon>Add Substance
                      </button>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="marginbottom10px"
                  *ngFor="let impuritiesSubstance of impurities.impuritiesSubstanceList; let impuritiesSubstanceIndex = index">
                  <app-impurities-substance-form [impuritiesSubstance]="impuritiesSubstance"
                    [impuritiesSubstanceIndex]="impuritiesSubstanceIndex">
                  </app-impurities-substance-form>
                </div>
              </mat-expansion-panel>


              <!--
              <div class="margintop20px">
                <div class="titleblue">
                  <div class="divflex titleblue">
                    <div matBadge="{{impurities.impuritiesSubstanceList.length}}" matBadgeColor="accent"
                      matBadgeSize="medium" matBadgeOverlap="false">
                      <span class="titleblue"><b>Substance</b></span>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div>
                      <button mat-raised-button color="primary" (click)="addNewImpuritiesSubstance()"
                        matTooltip="Add New Test">
                        <mat-icon svgIcon="add_circle_outline"></mat-icon>Add Substance
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="marginbottom10px"
                    *ngFor="let impuritiesSubstance of impurities.impuritiesSubstanceList; let impuritiesSubstanceIndex = index">
                    <app-impurities-substance-form [impuritiesSubstance]="impuritiesSubstance"
                      [impuritiesSubstanceIndex]="impuritiesSubstanceIndex">
                    </app-impurities-substance-form>
                  </div>
                </div>
              </div>
              -->
              <!-- IMPURITIES SUBSTANCE LIST END -->


              <!-- IMPURITIES TOTAL BEGIN -->

              <br>
              <mat-expansion-panel [expanded]="true" class="panel-style bordergray">
                <mat-expansion-panel-header class="">
                  <mat-panel-title>
                    <div class="titleblue">
                      <b>Total Impurities</b>
                    </div>
                  </mat-panel-title>
                  <mat-panel-description class="marginleft25px">
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <app-impurities-total-form [impuritiesTotal]="impurities.impuritiesTotal">
                </app-impurities-total-form>
              </mat-expansion-panel>

              <!--
              <mat-card class="margintop20px bordergray">
                <mat-card-title class="titleblue">
                  <b>Total Impurities</b>
                </mat-card-title>
                <mat-card-content>
                  <app-impurities-total-form [impuritiesTotal]="impurities.impuritiesTotal">
                  </app-impurities-total-form>
                </mat-card-content>
              </mat-card>
               -->
              <!-- IMPURITIES TOTAL END -->

            </mat-card-content>
          </mat-card>
          <br>
          <br>
        </div>

      </div>
    </div>
  </div>
</div>
<br><br><br>