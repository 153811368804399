<mat-toolbar class="mat-elevation-z3" color="primary" *ngIf="showHeaderBar === 'true'">
  <div class="logo-container">
    <span class="logo">
      <a routerLink="/home" aria-label="home">
        <img [src]="logoSrcPath" alt="gsrs-logo" />
      </a>
    </span>
    <span class="version" *ngIf="version && version !== ''" [matTooltip]="versionTooltipMessage"
      matTooltipPosition="below">Ver. {{version}}</span>
  </div>

  <!-- <span class="nav-big">
    <span class="nav-item" *ngFor="let navItem of navItems">
      <a [routerLink]="['/' + navItem.path]" [ngClass]="{active: mainPathSegment == navItem.path}">
        {{navItem.display}}
      </a>
    </span>
  </span> -->
  <!-- <span class="middle-fill"></span> -->
  <span class="nav-small">
    <button class = "top-button" mat-button mat-primary-button  style = "color:white;padding-top:10px; padding-bottom:10px;" [matMenuTriggerFor]="nav" (click)="increaseMenuZindex()" aria-label="main menu">
     <span style = "font-size:16px;">Menu &nbsp;</span><mat-icon svgIcon="menu"></mat-icon>
    </button>
    <mat-menu #nav="matMenu" (closed)="removeZindex()" class = "reg-a">
      <!--
      <a mat-menu-item [href]="baseDomain + clasicBaseHref + 'advancedSearch'" target="_blank" *ngIf="appId === 'fda'">
      -->
      <a mat-menu-item [routerLink]="['/advanced-search']">
        Advanced Search
      </a>
      <div *ngFor="let navItem of navItems">
        <a mat-menu-item [routerLink]="['/' + navItem.path]" *ngIf="navItem.path">
          {{navItem.display}}
        </a>
        <a mat-menu-item [href]="navItem.externalPath" target="_blank" *ngIf="navItem.externalPath">
          {{navItem.display}}
        </a>
        <ng-container *ngIf="navItem.children">
          <a mat-menu-item [matMenuTriggerFor]="childrenMenu">
            {{navItem.display}}
          </a>
          <mat-menu #childrenMenu="matMenu">
            <div *ngFor="let childNavItem of navItem.children">
              <a mat-menu-item [routerLink]="['/' + childNavItem.path]" [queryParams]="childNavItem.params||{}"
                *ngIf="childNavItem.path">
                {{childNavItem.display}}
              </a>
              <a mat-menu-item [href]="[childNavItem.href]"
                *ngIf="childNavItem.href">
                {{childNavItem.display}}
              </a>
              <a mat-menu-item [href]="'mailto:' + contactEmail + '?Subject=Support request'" *ngIf="childNavItem.display === 'Email GSRS Support'">
                {{childNavItem.display}}
              </a>
            </div>
            <a mat-menu-item *ngIf="navItem.display === 'Register'" (click)="importDialog()">
              Import Substance
            </a>
          </mat-menu>
        </ng-container>
      </div>
      <a mat-menu-item *ngIf="isAdmin" routerLink="/admin">
        Admin Panel
      </a>
      <a mat-menu-item [href]="'mailto:' + contactEmail">
        Contact Us
      </a>
    </mat-menu>
  </span>
  <div >
    <button class = "top-button extra-item " mat-button mat-primary-button style = "font-size:16px;color:white; padding-right:10px;margin-right:15px;padding-top:10px; padding-bottom:10px;" class = "menu-button extra-item"
    [routerLink]="['/browse-substance']" aria-label="browse substances" >Browse Substances</button>
  </div>
  <div *ngIf = "this.loadedComponents">
      <button class = "top-button extra-browse" mat-button mat-primary-button  style = "color:white;font-size:16px;padding-top:10px; padding-bottom:10px;" [matMenuTriggerFor]="browse" (click)="increaseMenuZindex()" aria-label="main menu" *ngIf = "loadedComponents">
          <span style = "font-size:16px;">Browse Other</span>
         <mat-icon svgIcon="chevron_down"></mat-icon>
         </button>
         <mat-menu #browse="matMenu" (closed)="removeZindex()">
           <span *ngIf = "loadedComponents.applications">
      <a mat-menu-item routerLink="/browse-applications" >
        Browse Applications
      </a>
      <mat-divider></mat-divider>
    </span>
    <span *ngIf = "loadedComponents.products">
      <a mat-menu-item routerLink="/browse-products">
        Browse Products
      </a>
      <mat-divider></mat-divider>
    </span>
    <span *ngIf = "loadedComponents.clinicaltrials">
      <a mat-menu-item routerLink="/browse-clinical-trials">
        Browse Clinical Trials
      </a>
      <mat-divider></mat-divider>
          </span>

    <span *ngIf = "loadedComponents.adverseevents">
      <a mat-menu-item routerLink="/browse-adverse-events">
        Browse Adverse Events
      </a>
      <div *ngIf="adverseEventShinyHomepageDisplay === true">
        <mat-divider></mat-divider>

        <a mat-menu-item [href]="adverseEventShinyHomepageURL" target="_blank">
          Browse Adverse Events
        </a>
        </div>
      </span>

      </mat-menu>
        </div><div>
      <button class = "top-button extra-item" mat-button mat-primary-button  style = "color:white;font-size:16px;padding-top:10px; padding-bottom:10px;" [matMenuTriggerFor]="reg" (click)="increaseMenuZindex()" aria-label="main menu" *ngIf = "canRegister">
          <span style = "font-size:16px;">Register</span>
         <mat-icon svgIcon="chevron_down"></mat-icon>
         </button>
         <button class = "top-button extra-item" mat-button mat-primary-button style = "font-size:16px;color:white; padding-right:10px;margin-right:15px;padding-top:10px; padding-bottom:10px;" class = "menu-button extra-item"
    [routerLink]="['/advanced-search']" aria-label="advanced search" >Advanced Search</button>

  </div>

     <div>
     <mat-menu #reg="matMenu" (closed)="removeZindex()">
       <div *ngFor="let navItem of registerNav">
         <a mat-menu-item [routerLink]="['/' + navItem.path]" *ngIf="navItem.path">
           {{navItem.display}}
         </a>
         <a mat-menu-item [href]="navItem.externalPath" target="_blank" *ngIf="navItem.externalPath">
           {{navItem.display}}
         </a>

       </div>
       <div>
          <a mat-menu-item (click)="importDialog()">
              Import Substance
            </a>
       </div>
     </mat-menu>
    </div>
  <span class="middle-fill"></span>
  <app-substance-text-search class="top-search" [searchValue]="searchValue" placeholder = "Search Substances"
    (searchPerformed)="processSubstanceSearch($event)" eventCategory="topSearch" (opened)="increaseMenuZindex()"
    (closed)="removeZindex()">
  </app-substance-text-search>
  <div>
    <button mat-button class="login-link" (click)="routeToLogin()" *ngIf="!auth && mainPathSegment !== 'login'">
      Login
    </button>
    <div class="logged-in" *ngIf="auth">
      <button aria-label="User Account" class="user-button main-button" mat-icon-button [matMenuTriggerFor]="accountMenu" (click)="increaseMenuZindex()" >
        <mat-icon class="user-icon" svgIcon="account_circle" ></mat-icon>
      </button>
      <button aria-label="User Account dropdown" class="user-button arrow-button test-class" mat-icon-button [matMenuTriggerFor]="accountMenu" (click)="increaseMenuZindex()" >
        <mat-icon class="user-icon icon-two" svgIcon="chevron_down"></mat-icon>
      </button>
      <br />
      <span class="user" *ngIf="auth && auth.identifier" matTooltip="{{auth.identifier}}">{{auth.identifier}}</span>
    </div>
    <mat-menu #accountMenu="matMenu" (closed)="removeZindex()">
      <a mat-menu-item (click)="openProfile()">
        <span>Profile</span>
      </a>
      <a mat-menu-item routerLink="/user-downloads">
        <span>User Downloads</span>
      </a>
      <a mat-menu-item (click)="viewDrafts()">
        <span>Saved Edit Drafts</span>
      </a>
      <a routerLink="/admin" mat-menu-item *ngIf="isAdmin">
        <span>Admin Panel</span>
      </a>
      <a [routerLink]="['/registrars']" class = "add-at-low-res" *ngIf = "canRegister" mat-menu-item >
        <span>Registrars</span>
      </a>
      <button mat-menu-item (click)="logout()">
        <span>Logout</span>
      </button>
    </mat-menu>
  </div>
  <div class="classic-view-container"  *ngIf="auth && auth.identifier">
      <a class = "top-button hide-at-low-res" mat-button mat-primary-button  style = "color:white;font-size:16px;padding-top:10px; padding-bottom:10px;" [routerLink]="['/registrars']" *ngIf = "canRegister">
          <span style = "font-size:16px;">Registrars</span>

  </a>
  </div>
</mat-toolbar>
<app-main-notification></app-main-notification>
<app-loading></app-loading>
<router-outlet></router-outlet>